import React, { useState } from "react";
import {
  Button,
  IconAction,
  Input,
  Modal,
  notification,
  TextArea,
} from "../../../../components/admin/ui";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import styled, { css } from "styled-components";
import { keyframes } from "../../../../styles";
import Text from "antd/lib/typography";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { isEmpty } from "lodash";
import { Upload } from "../../../../components/admin";
import Image from "antd/lib/image";
import Space from "antd/lib/space";
import Title from "antd/lib/typography/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "antd/lib/card/Card";

export const ProcessesList = ({
  label,
  job,
  value = [],
  onChange,
  required = false,
  error = false,
  hidden = false,
  disabled = false,
}) => {
  const [modalProcess, setModalProcess] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [actionType, setActionType] = useState("add");
  const [process, setProcess] = useState(null);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [photo, setPhoto] = useState(null);

  const onSetActionType = (actionType) => setActionType(actionType);

  const resetData = () => {
    setName("");
    setDescription("");
    setPhoto(null);
  };

  const onSetModalProcess = (modalProcess = !modalProcess) =>
    setModalProcess(modalProcess);

  const onSaveProcess = () => {
    if (isEmpty(name) || isEmpty(description) || isEmpty(photo))
      return notification({
        type: "info",
        title: "Complete todos los campos!",
      });

    if (actionType === "add") {
      return addProcess();
    }

    return updateProcess();
  };

  const addProcess = () => {
    const process = {
      name: name,
      description: description,
      photo: photo,
    };

    setName("");
    setDescription("");
    setPhoto(null);

    onChange([...value, process]);

    onSetModalProcess(false);
    resetData();
  };

  const updateProcess = () => {};

  const removeProcess = (option) => {
    const optionsFilter = Object.entries(value)
      .map(([key, option_]) => ({
        index: +key,
        ...option_,
      }))
      .filter((option_) => option_.index !== option.index);

    onChange(
      optionsFilter.map((option_) => ({
        name: option_.name,
        description: option_.description,
        photo: option_.photo,
      }))
    );
  };

  const openModalForEdit = (option) => {
    setName(option.name);
    setDescription(option.description);
    setPhoto(option.photo);

    onSetModalProcess(true);
  };

  return (
    <>
      <Card
        title="Procesos del proyecto"
        extra={[
          <span className="pointer">
            <Button
              type="primary"
              onClick={() => {
                resetData();
                onSetActionType("add");
                onSetModalProcess(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} size="lg" /> &nbsp; Add
            </Button>
          </span>,
        ]}
      >
        <Container hidden={hidden} disabled={disabled} error={error}>
          <div>
            {value && (
              <WrapperList>
                {value.map((option, index) => (
                  <Row key={index} className="item-list">
                    <Col xs={24} sm={3}>
                      <Image
                        src={
                          option?.photo?.url ||
                          "https://firebasestorage.googleapis.com/v0/b/sendingemails-348505.appspot.com/o/resources%2Fimage-not-found.jpg?alt=media&token=35125bc7-a978-4ee0-8d01-d820b79b24b6"
                        }
                        width={90}
                        height={60}
                        style={{ objectFit: "contain" }}
                        alt="process"
                      />
                    </Col>
                    <Col xs={24} sm={18}>
                      <div style={{ display: "block" }}>
                        <div style={{ textAlign: "left" }}>
                          <strong>{option?.name.toUpperCase()}</strong>
                        </div>
                        <div>
                          <span>{option?.description}</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={3}>
                      <Space>
                        {/*<IconAction*/}
                        {/*  icon={faEdit}*/}
                        {/*  onClick={() => {*/}
                        {/*    onSetActionType("edit");*/}
                        {/*    openModalForEdit({ index, ...option });*/}
                        {/*  }}*/}
                        {/*  styled={{ color: (theme) => theme.colors.black }}*/}
                        {/*  size={33}*/}
                        {/*/>*/}
                        <IconAction
                          icon={faTrash}
                          onClick={() => removeProcess({ index, ...option })}
                          styled={{ color: (theme) => theme.colors.error }}
                          size={33}
                        />
                      </Space>
                    </Col>
                  </Row>
                ))}
              </WrapperList>
            )}
          </div>
        </Container>
      </Card>
      <Modal
        closable
        open={modalProcess}
        onCancel={() => onSetModalProcess(false)}
        title={<Title level={2}>Proceso</Title>}
      >
        <Row gutter={[10, 16]}>
          <Col span={24}>
            <Label label={label} required={required}>
              {label}
            </Label>
          </Col>
          <Col span={24}>
            <Input
              label="Nombre proceso"
              animation={false}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col span={24}>
            <TextArea
              label="Descripción"
              animation={false}
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Col>
          <Col span={24}>
            <Upload
              key={name || photo}
              label="Foto de proceso (700x400)"
              accept="image/*"
              value={photo}
              filePath={`jobs/${job.id}/processes`}
              buttonText="Subir imagen"
              onChange={(file) => setPhoto(file)}
              onUploading={setUploadingImage}
            />
          </Col>
          <Col span={24}>
            <Button
              block
              size="large"
              disabled={uploadingImage}
              onClick={() => onSaveProcess()}
            >
              SAVE
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const Container = styled.div`
  ${({ theme, hidden, disabled, error }) => css`
    position: relative;
    width: inherit;
    border-radius: ${theme.border_radius.xx_small};
    background: ${theme.colors.white};
    border: 1px solid ${error ? theme.colors.error : theme.colors.gray};
    animation: ${error && keyframes.shake} 340ms
      cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    padding: 0.8em 1em;

    ${hidden &&
    css`
      display: none;
    `}

    ${disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
  `}
`;

const Label = styled.label`
  ${({ label, required }) =>
    required &&
    label &&
    `   ::after {
          margin-right: 3px;
            color: red;
            content: "*";
        }
    `}
`;

const WrapperList = styled.div`
  display: flex;
  flex-direction: column;

  .item-list {
    background: #f6f6f6;
    border-bottom: 1px solid lightgrey;
    padding: 0.2em;
    div {
      padding: 0 0.3em;
      display: flex;
      align-items: center;
      justify-content: start;
    }

    div:last-child {
      border-bottom: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const ErrorItem = styled(Text)`
  ${({ theme, error }) => css`
    color: ${theme.colors.error};
    font-size: ${theme.font_sizes.x_small};
    ${error &&
    css`
      animation: ${keyframes.shake} 340ms;
    `}
  `}
`;
