import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { Controller, useForm } from "react-hook-form";
import {
  Checkbox,
  Form,
  Input,
  notification,
  Select,
  TextArea,
} from "../../admin/ui";
import { Button } from "./Button";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useDevice, useFormUtils } from "../../../hooks";
import { phoneCodes } from "../../../data-list";
import { iubiSalesApiUrl } from "../../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

export const FormContact = ({ isModalContactOpen, handleOk }) => {
  const { isMobile } = useDevice();
  const [savingContact, setSavingContact] = useState(false);

  const schema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    phoneNumber: yup.number().required(),
    message: yup.string().required(),
    acceptTermsAndConditions: yup.boolean().required(),
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { required, error } = useFormUtils({ errors, schema });

  useEffect(() => {
    resetContactForm();
  }, [isModalContactOpen]);

  const onSubmitContact = async (formData) => {
    try {
      setSavingContact(true);

      if (!watch("acceptTermsAndConditions"))
        return notification({
          title: "Acepte los términos y condiciones para enviar su mensaje",
          type: "warning",
        });

      const contact = mapContactData(formData);

      const response = await fetchSendEmail(contact);

      if (!response.ok) throw new Error(response.statusText);

      notification({ type: "success", title: "Enviado exitosamente" });

      resetContactForm();

      handleOk && handleOk();
    } catch (e) {
      console.error("ErrorSaveBenefit: ", e);
      notification({ type: "error" });
    } finally {
      setSavingContact(false);
    }
  };

  const mapContactData = (formData) => ({
    contact: {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: {
        number: formData.phoneNumber,
        countryCode: formData.countryCode,
      },
      message: formData.message,
      termsAndConditions: formData?.acceptTermsAndConditions || true,
      hostname: "iubi.pe",
    },
  });

  const fetchSendEmail = async (contact) =>
    await fetch(`${iubiSalesApiUrl}/emails/contact`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": null,
        "content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(contact),
    });

  const resetContactForm = () =>
    reset({
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "+51",
      phoneNumber: "",
      message: "",
      acceptTermsAndConditions: true,
    });

  const onSubmit = (formData) => onSubmitContact(formData);

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <Input
                label="Ingrese nombres"
                size="large"
                name={name}
                value={value}
                onChange={onChange}
                error={error(name)}
                required={required(name)}
              />
            )}
          />
        </Col>
        <Col span={24}>
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <Input
                label="Ingrese apellidos"
                size="large"
                name={name}
                value={value}
                onChange={onChange}
                error={error(name)}
                required={required(name)}
              />
            )}
          />
        </Col>
        <Col span={24}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <Input
                label="Ingrese email"
                size="large"
                name={name}
                value={value}
                onChange={onChange}
                error={error(name)}
                required={required(name)}
              />
            )}
          />
        </Col>
        <Col xs={24} sm={24} md={10}>
          <Controller
            name="countryCode"
            control={control}
            defaultValue="+51"
            render={({ field: { onChange, value, name } }) => (
              <Select
                label="Código"
                value={value}
                onChange={(value) => onChange(value)}
                error={error(name)}
                required={required(name)}
                isMobile={isMobile}
                options={phoneCodes.map((phoneCode) => ({
                  code: phoneCode.code,
                  value: phoneCode.dial_code,
                  label: `${phoneCode.name} (${phoneCode.dial_code})`,
                }))}
              />
            )}
          />
        </Col>
        <Col xs={24} sm={24} md={14}>
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue="+51"
            render={({ field: { onChange, value, name } }) => (
              <Input
                label="Ingrese teléfono"
                size="large"
                name={name}
                value={value}
                onChange={onChange}
                error={error(name)}
                required={required(name)}
              />
            )}
          />
        </Col>
        <Col span={24}>
          <Controller
            name="message"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <TextArea
                label="Ingrese mensaje"
                size="large"
                name={name}
                value={value}
                onChange={onChange}
                error={error(name)}
                required={required(name)}
              />
            )}
          />
        </Col>
        <Col span={24}>
          <p className="item-checkbox">
            <Controller
              name="acceptTermsAndConditions"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <Checkbox
                  name={name}
                  checked={value}
                  onChange={onChange}
                  error={error(name)}
                  required={required(name)}
                >
                  He leído la Política de privacidad, acepto recibir sus
                  comunicaciones y sus terminos y condiciones.
                </Checkbox>
              )}
            />
          </p>
        </Col>
        <Col span={24}>
          <div className="strong-description">
            <strong>
              Felicitaciones por tomar acción, miles de clientes esperan conocer
              tu proyecto.
            </strong>
          </div>
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            size="large"
            margin="1em auto"
            block
            htmlType="submit"
            loading={savingContact}
          >
            <FontAwesomeIcon icon={faPaperPlane} /> &nbsp; Enviar
          </Button>
        </Col>
      </Row>
    </FormContainer>
  );
};

const FormContainer = styled(Form)`
  .item-checkbox {
    span {
      color: #fff;
    }
  }
`;
