import React from "react";
import { useNavigate } from "react-router";
import { useDevice } from "../../../hooks";
import { HeaderMenuMobile } from "./HeaderMenuMobile";
import { HeaderMenu } from "./HeaderMenu";
import styled from "styled-components";
import { mediaQuery } from "../../../styles";
import { useAuthentication } from "../../../providers";

export const Header = ({ onSetVisibleDrawer, menuList }) => {
  const navigate = useNavigate();
  const { authUser } = useAuthentication();
  const { isMobile } = useDevice();

  const onNavigate = (url) => navigate(url);

  const isActiveLightTheme = ["/about-us", "/contact"].includes(
    window.location.pathname
  );

  return (
    <Container>
      {isMobile ? (
        <HeaderMenuMobile
          user={authUser}
          onSetVisibleDrawer={onSetVisibleDrawer}
          onNavigate={onNavigate}
          isActiveLightTheme={isActiveLightTheme}
        />
      ) : (
        <HeaderMenu
          user={authUser}
          onSetVisibleDrawer={onSetVisibleDrawer}
          onNavigate={onNavigate}
          menuList={menuList}
          isActiveLightTheme={isActiveLightTheme}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 0.4rem 2em;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 500;
  ${mediaQuery.minDesktop} {
    padding: 1rem 3em;
  }
`;
