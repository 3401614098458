import React from "react";
import styled from "styled-components";
import { FormContact, HelmetComponent } from "../../components";
import { BannerContactDesk, BannerContactMb } from "../../images";
import { useDevice } from "../../hooks";
import { mediaQuery } from "../../styles";
import { contactData } from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export const Contact = () => {
  const { isMobile } = useDevice();

  return (
    <>
      <HelmetComponent
        config={{
          title: `IUBI - Contáctanos`,
          url: "https://iubi.pe/contact",
          image:
            "https://iubi.pe/static/media/banner-1.38fd62cfff3d2b466fa9.webp",
        }}
      />
      <Container>
        <div className="wrapper-banner">
          <img
            src={isMobile ? BannerContactMb : BannerContactDesk}
            alt="sobre nosotros - iubi"
          />
        </div>
        <div className="wrapper-items">
          <div className="content-left">
            <div className="text-content">
              <h4>¿Listo para empezar ese gran proyecto?</h4>
              <p>
                No esperes más para tomar acción, miles de clientes esperan
                conocer tu proyecto.
              </p>
            </div>
          </div>
          <div className="content-right">
            <WrapperForm>
              <h4>COMENCEMOS TU PROYECTO</h4>
              <FormContact />
            </WrapperForm>
          </div>
        </div>
        <div className="wrapper-social">
          <div className="items-socials">
            <ul>
              <li>
                <a
                  href={contactData.socialLinks.whatsapp}
                  target="_blank"
                  aria-label="Boton de Whatsapp"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faWhatsapp} />
                </a>
              </li>
              <li>
                <a
                  href={contactData.socialLinks.facebook}
                  target="_blank"
                  aria-label="Boton de Facebook"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </li>
              <li>
                <a
                  href={contactData.socialLinks.instagram}
                  target="_blank"
                  aria-label="Boton de Instagram"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li>
                <a
                  href={contactData.socialLinks.tiktok}
                  target="_blank"
                  aria-label="Boton de tiktok"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTiktok} />
                </a>
              </li>
              <li>
                <a
                  href={contactData.socialLinks.email}
                  target="_blank"
                  aria-label="Boton de email"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: auto;
  .wrapper-banner {
    img {
      width: 100%;
      height: auto;
    }
  }
  .wrapper-items {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    ${mediaQuery.minDesktop} {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
    }
    .content-left {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4em 0;
      ${mediaQuery.minDesktop} {
        padding: 0;
      }
      .text-content {
        width: 70%;
        text-align: center;
        ${mediaQuery.minDesktop} {
          width: 50%;
          text-align: left;
        }
        h4 {
          color: ${({ theme }) => theme.colors.primary};
          font-size: 2.7em;
          font-family: "MontserratLight", serif;
        }
        p {
          font-size: ${({ theme }) => theme.font_sizes.x_large};
          color: ${({ theme }) => theme.colors.font3};
          font-family: "MontserratLight", serif;
        }
      }
    }
    .content-right {
      padding: 0 2em 2em 2em;
      display: flex;
      justify-content: center;
      ${mediaQuery.minMobile} {
        padding: 0 2em 4em 3em;
      }

      ${mediaQuery.minDesktop} {
        justify-content: start;
        padding: 6em 3em;
      }
    }
  }

  .wrapper-social {
    .items-socials {
      width: 100%;
      margin: 3rem auto;
      display: flex;
      justify-content: center;

      ul {
        width: auto;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        margin: auto;

        li a {
          color: ${({ theme }) => theme.colors.black};
          padding: 0 1rem;
          margin: 1rem;
          font-size: 3rem;
        }
      }
    }
  }
`;

const WrapperForm = styled.div`
  background: ${({ theme }) => theme.colors.secondary} !important;
  color: ${({ theme }) => theme.colors.white};
  padding: 1em 2em;
  border-radius: 2em;
  max-width: 100%;
  ${mediaQuery.minMobile} {
    max-width: 40em;
  }
  ${mediaQuery.minDesktop} {
    max-width: 40em;
  }
  h4 {
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 2em;
    margin: 1em auto;
  }
`;
