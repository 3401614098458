import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { useNavigate, useParams } from "react-router";
import Title from "antd/lib/typography/Title";
import {
  Button,
  Form,
  Input,
  InputNumber,
  notification,
  TextArea,
} from "../../../../components/admin/ui";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDefaultFirestoreProps, useFormUtils } from "../../../../hooks";
import { Upload } from "../../../../components/admin";
import { firestore } from "../../../../firebase";
import { useGlobalData } from "../../../../providers";
import { assign } from "lodash";

export const ProcessIntegration = () => {
  const navigate = useNavigate();
  const { processId } = useParams();
  const { assignCreateProps, assignUpdateProps } = useDefaultFirestoreProps();

  const { processes } = useGlobalData();

  const [process, setProcess] = useState({});
  const [savingProcess, setSavingProcess] = useState(false);

  useEffect(() => {
    const _process =
      processId === "new"
        ? { id: firestore.collection("processes").doc().id }
        : processes.find((process) => process.id === processId);

    if (!_process) return navigate(-1);

    setProcess(_process);
  }, []);

  const onSubmitSaveProcess = async (formData) => {
    try {
      setSavingProcess(true);

      await firestore
        .collection("processes")
        .doc(process.id)
        .set(
          processId === "new"
            ? assignCreateProps(mapProcess(process, formData))
            : assignUpdateProps(mapProcess(process, formData)),
          { merge: true }
        );

      notification({ type: "success" });

      onGoBack();
    } catch (e) {
      console.log("ErrorSaveProcess: ", e);
      notification({ type: "error" });
    } finally {
      setSavingProcess(false);
    }
  };

  const mapProcess = (process, formData) =>
    assign(
      {},
      {
        id: process.id,
        number: formData.number,
        name: formData.name.toLowerCase(),
        description: formData.description,
        photo: formData.photo,
      }
    );

  const onGoBack = () => navigate(-1);

  return (
    <Process
      process={process}
      onSubmitSaveProcess={onSubmitSaveProcess}
      onGoBack={onGoBack}
      savingProcess={savingProcess}
    />
  );
};

const Process = ({ process, onSubmitSaveProcess, savingProcess, onGoBack }) => {
  const [uploadingImage, setUploadingImage] = useState(false);

  const schema = yup.object({
    number: yup.number().required(),
    name: yup.string().required(),
    description: yup.string().required(),
    photo: yup.mixed().notRequired(),
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { required, error } = useFormUtils({ errors, schema });

  useEffect(() => {
    resetForm();
  }, [process]);

  const resetForm = () => {
    reset({
      number: process?.number || null,
      name: process?.name || "",
      description: process?.description || "",
      photo: process?.photo || null,
    });
  };

  const submitSaveProcess = (formData) => onSubmitSaveProcess(formData);

  return (
    <Row>
      <Col span={24}>
        <Title level={3}>Procesos</Title>
      </Col>
      <Col span={24}>
        <Form onSubmit={handleSubmit(submitSaveProcess)}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Controller
                name="number"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, name } }) => (
                  <InputNumber
                    label="Numero"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={error(name)}
                    required={required(name)}
                  />
                )}
              />
            </Col>
            <Col span={24}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, name } }) => (
                  <Input
                    label="Nombre"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={error(name)}
                    required={required(name)}
                  />
                )}
              />
            </Col>
            <Col span={24}>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, name } }) => (
                  <TextArea
                    label="Descripción"
                    rows={4}
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={error(name)}
                    required={required(name)}
                  />
                )}
              />
            </Col>
            <Col span={24}>
              <Controller
                name="photo"
                control={control}
                defaultValue={null}
                render={({ field: { onChange, value, name } }) => (
                  <Upload
                    label="Foto de proceso (700x700)"
                    accept="image/*"
                    name={name}
                    value={value}
                    filePath={`processes/${process.id}`}
                    buttonText="Subir imagen"
                    error={error(name)}
                    required={required(name)}
                    onChange={(file) => onChange(file)}
                    onUploading={setUploadingImage}
                  />
                )}
              />
            </Col>
          </Row>
          <Row justify="end" gutter={[16, 16]}>
            <Col xs={24} sm={6} md={4}>
              <Button
                type="default"
                size="large"
                block
                onClick={() => onGoBack()}
                disabled={uploadingImage | savingProcess}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24} sm={6} md={4}>
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                disabled={uploadingImage | savingProcess}
                loading={savingProcess}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};
