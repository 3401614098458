import React, { useEffect, useState } from "react";
import { Modal } from "../Modal";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../Input";
import { Upload } from "../../Upload";
import { Button } from "../Button";
import { Form } from "../Form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormUtils } from "../../../../hooks";
import Title from "antd/lib/typography/Title";
import Card from "antd/lib/card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Image from "antd/lib/image";
import Space from "antd/lib/space";
import styled from "styled-components";

export const Information = ({
  open,
  service,
  onSubmitSaveService,
  onSetModal,
  onSetModalInformation,
}) => {
  const [savingService, setSavingService] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);

  const schema = yup.object({
    name: yup.string().required(),
    servicePhoto: yup.mixed().notRequired(),
    coverPhoto: yup.mixed().notRequired(),
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { required, error } = useFormUtils({ errors, schema });

  useEffect(() => {
    resetForm();
  }, [service]);

  const resetForm = () => {
    reset({
      name: service?.name || "",
      servicePhoto: service?.servicePhoto || null,
      coverPhoto: service?.coverPhoto || null,
    });
  };

  const submitSaveInformation = async (formData) => {
    try {
      setSavingService(true);

      const information = mapInformation(formData);

      await onSubmitSaveService(information);
      onSetModal();
    } finally {
      setSavingService(false);
    }
  };

  const mapInformation = (formData) => ({
    name: formData.name.toLowerCase(),
    servicePhoto: formData?.servicePhoto || null,
    coverPhoto: formData?.coverPhoto || null,
  });

  return (
    <>
      <Card
        title="Informacion principal"
        extra={
          <Button type="primary" onClick={() => onSetModalInformation()}>
            <FontAwesomeIcon icon={faEdit} size="lg" />
            &nbsp; Edit
          </Button>
        }
        bodyStyle={{ display: !service.name ? "none" : "inherit" }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div className="d-flex">
              <TitleItem>
                <span>Titulo:</span> <strong> {service.name}</strong>
              </TitleItem>
            </div>
          </Col>
          <Col span={24}>
            <Image.PreviewGroup>
              <Space>
                <Image
                  width={150}
                  height={100}
                  src={service.servicePhoto?.thumbUrl}
                />
                <Image
                  width={150}
                  height={100}
                  src={service.coverPhoto?.thumbUrl}
                />
              </Space>
            </Image.PreviewGroup>
          </Col>
        </Row>
      </Card>
      <Modal
        closable
        open={open}
        onCancel={() => onSetModal()}
        title={<Title level={2}>Información principal</Title>}
      >
        <Form onSubmit={handleSubmit(submitSaveInformation)}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, name } }) => (
                  <Input
                    label="Nombre"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={error(name)}
                    required={required(name)}
                  />
                )}
              />
            </Col>
            <Col xs={24} sm={12}>
              <Controller
                name="servicePhoto"
                control={control}
                defaultValue={null}
                render={({ field: { onChange, value, name } }) => (
                  <Upload
                    label="Foto de servicio (290x210)"
                    accept="image/*"
                    name={name}
                    value={value}
                    filePath={`services/${service.id}`}
                    resize="290x210"
                    buttonText="Subir imagen"
                    error={error(name)}
                    required={required(name)}
                    onChange={(file) => onChange(file)}
                    onUploading={setUploadingImage}
                  />
                )}
              />
            </Col>
            <Col xs={24} sm={12}>
              <Controller
                name="coverPhoto"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <Upload
                    label="Foto de portada (1460x560)"
                    accept="image/*"
                    name={name}
                    value={value}
                    filePath={`services/${service.id}`}
                    resize="1480x650"
                    buttonText="Subir imagen"
                    error={error(name)}
                    required={required(name)}
                    onChange={(file) => onChange(file)}
                    onUploading={setUploadingImage}
                  />
                )}
              />
            </Col>
          </Row>
          <Row justify="end" gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8}>
              <Button
                type="default"
                size="large"
                block
                onClick={() => onSetModal()}
                disabled={uploadingImage | savingService}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                disabled={uploadingImage | savingService}
                loading={savingService}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const TitleItem = styled.div`
  display: flex;
  gap: 0.5em;
  font-size: 1em;
  text-transform: capitalize;
`;
