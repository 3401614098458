import React from "react";
import { Layout } from "antd";

const { Footer } = Layout;

export const FooterLayout = () => {
  return (
    <Footer style={{ textAlign: "center" }}>
      iubi.pe ©2023 Created by <strong>IUBI Company</strong>
    </Footer>
  );
};
