import React from "react";
import styled from "styled-components";
import { HelmetComponent, ServicesSection } from "../../components";
import { useGlobalData } from "../../providers";
import { useNavigate } from "react-router";

export const Services = () => {
  const navigate = useNavigate();

  const { services } = useGlobalData();

  const onNavigateTo = (pathname) => navigate(pathname);

  return (
    <>
      <HelmetComponent
        config={{
          title: `IUBI - Nuestros servicios`,
          url: "https://iubi.pe/services",
          image:
            "https://iubi.pe/static/media/banner-1.38fd62cfff3d2b466fa9.webp",
        }}
      />
      <Container>
        <ServicesSection services={services} onNavigateTo={onNavigateTo} />
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 3rem 1em;
`;
