import React from "react";
import styled from "styled-components";
import { CardService } from "./CardService";
import { mediaQuery } from "../../../styles";

export const ServicesSection = ({ services = [], onNavigateTo }) => {
  return (
    <Container>
      <h2>Nuestros servicios</h2>
      <ul className="services-list">
        {services.map((service, index) => (
          <li
            className="service-item"
            key={index}
            onClick={() => onNavigateTo(`/services/${service.nameId}`)}
          >
            <CardService name={service.name} image={service.servicePhoto.url} />
          </li>
        ))}
      </ul>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  margin: 2em 0;
  padding: 3em;
  h2 {
    color: ${({ theme }) => theme.colors.primary};
    text-align: center;
    margin-bottom: 1.3em;
  }
  .services-list {
    width: 100%;
    height: auto;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1em;
    ${mediaQuery.minMobile} {
      justify-content: space-between;
    }
    .service-item {
      max-width: 19em;
      width: 100%;
      height: 100%;
    }
  }
`;
