import React from "react";
import AntCarousel from "antd/lib/carousel";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useDevice } from "../../../hooks";
import { mediaQuery } from "../../../styles/constants/mediaQuery";

export const Carousel = ({ images = [] }) => {
  const { isMobile } = useDevice();

  return (
    <Container>
      <AntCarousel
        autoplay
        autoplaySpeed={4 * 1000}
        arrows={!isMobile}
        prevArrow={<FontAwesomeIcon icon={faChevronLeft} />}
        nextArrow={<FontAwesomeIcon icon={faChevronRight} />}
      >
        {images.map((img, index) => (
          <WrapperSlide key={index}>
            <img
              src={img}
              className="img-slide"
              width={isMobile ? "823" : "992"}
              height={isMobile ? "890" : "377"}
              alt="Banner IUBI"
            />
          </WrapperSlide>
        ))}
      </AntCarousel>
    </Container>
  );
};

const Container = styled.div`
  width: 97%;
  border-radius: 2em;
  height: auto;
  overflow: hidden;
  margin: 1em auto;
  position: relative;
  ${mediaQuery.minDesktop} {
    .ant-carousel .slick-prev,
    .ant-carousel .slick-next {
      top: 46%;
    }
    .ant-carousel .slick-next,
    .ant-carousel .slick-next {
      top: 46%;
    }
    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
      color: #fff;
      z-index: 99999;
      background: rgba(0, 0, 0, 0.2);
      padding: 0.5rem;
    }
    .slick-arrow.slick-prev {
      left: 1%;
    }
    .slick-arrow.slick-next {
      right: 1%;
    }
  }

  .ant-carousel .slick-dots-bottom {
    bottom: 0;
  }

  .slick-dots {
    margin: 1.5rem auto 2rem auto;
    li.slick-active {
      width: auto !important;
    }
    li {
      width: auto !important;
      margin: 0 7px;
      button {
        width: 14px !important;
        height: 14px !important;
      }
    }
  }
`;

const WrapperSlide = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .img-slide {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
