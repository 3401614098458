import React, { useState } from "react";
import { Button } from "./Button";
import styled from "styled-components";
import { ModalContact } from "./ModalContact";
import { mediaQuery } from "../../../styles";

export const ContactUs = ({
  title = "¿Listo para comenzar tu proyecto?",
  buttonText = "QUIERO EMPEZAR MI PROYECTO",
}) => {
  const [isModalContactOpen, setIsModalContactOpen] = useState(false);

  const onHandleVisibleContactForm = () =>
    setIsModalContactOpen(!isModalContactOpen);

  return (
    <Container>
      <div className="wrapper-initial-project">
        <h3>{title}</h3>
        <p>
          No esperes mas para tomar acción, miles de clientes esperan conocer tu
          proyecto.
        </p>
        <Button
          type="primary"
          size="lg"
          width="auto"
          onClick={() => onHandleVisibleContactForm(true)}
        >
          <span style={{ textTransform: "uppercase" }}>{buttonText}</span>
        </Button>
      </div>
      <ModalContact
        isModalContactOpen={isModalContactOpen}
        handleOk={onHandleVisibleContactForm}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  .wrapper-initial-project {
    margin: 8em auto;
    h3 {
      text-align: center;
      color: ${({ theme }) => theme.colors.primary};
      line-height: 1em;
      width: 97%;
      margin: auto;
    }
    p {
      text-align: center;
      color: ${({ theme }) => theme.colors.font1};
      width: 97%;
      margin: auto;
      padding: 1.8em 0;
      ${mediaQuery.minTablet} {
        width: 23em;
      }
    }
  }
`;
