import React, { useState } from "react";
import styled from "styled-components";
import { Drawer } from "./Drawer";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { ButtonsFloating, WrapperContainer } from "../ui";
import { capitalize } from "lodash";
import { useGlobalData } from "../../../providers";

export const BaseLayout = ({ children }) => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const { services } = useGlobalData();

  const pathName = window.location.pathname;

  // const gaEventTracker = useAnalyticsEventTracker("Button");

  // const contactClickHandler = (action, label) => gaEventTracker(action, label);

  const menuList = [
    {
      key: "/",
      label: "inicio",
      url: "/",
    },
    {
      key: "/about-us",
      label: "nosotros",
      url: "/about-us",
    },
    {
      key: "/services",
      label: "servicios",
      url: "/services",
      subMenu: services.map((service) => ({
        key: `/services/${service.nameId}`,
        label: capitalize(service.name),
        url: `/services/${service.nameId}`,
      })),
    },
    {
      key: "/contact",
      label: "contácto",
      url: "/contact",
    },
  ];

  return (
    <LayoutContainer>
      <WrapperContainer>
        <LayoutContent>
          <Drawer
            visibleDrawer={visibleDrawer}
            onSetVisibleDrawer={setVisibleDrawer}
            menuList={menuList}
          />
          <Header onSetVisibleDrawer={setVisibleDrawer} menuList={menuList} />
          <div className="body">{children}</div>
          <Footer />
          {["/", "/about-us", "/contact", "/services"].includes(pathName) && (
            <ButtonsFloating />
          )}
        </LayoutContent>
      </WrapperContainer>
    </LayoutContainer>
  );
};

const LayoutContainer = styled.div`
  width: 100vw;
  background: ${({ theme }) => theme.colors.white};
`;

const LayoutContent = styled.div`
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: inherit;
`;
