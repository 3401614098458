import { TermsAndConditions } from "../components";
import React from "react";

export const legalNotices = {
  "politica-de-privacidad": {
    title: "Política de Privacidad",
    component: <TermsAndConditions />,
  },
  "politica-de-cookies": {
    title: "Política de Cookies",
    component: <TermsAndConditions />,
  },
  "terminos-y-condiciones": {
    title: "Términos y Condiciones",
    component: <TermsAndConditions />,
  },
  "politica-de-terminos-y-condiciones-de-contratacion": {
    title: "Política de Términos y Condiciones de Contratación",
    component: <TermsAndConditions />,
  },
};
