import React, { createContext, useContext, useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore } from "../firebase";
import { useAuthentication } from "./AuthenticationProvider";
import { notification, Spinner } from "../components/admin/ui";
import { orderBy } from "lodash";

const GlobalDataContext = createContext({
  services: [],
  users: [],
});

export const GlobalDataProvider = ({ children }) => {
  const { authUser } = useAuthentication();

  const [users = [], usersLoading, usersError] = useCollectionData(
    authUser
      ? firestore.collection("users").where("isDeleted", "==", false)
      : null
  );

  const [services = [], servicesLoading, servicesError] = useCollectionData(
    firestore.collection("services").where("isDeleted", "==", false) || null
  );

  const [benefits = [], benefitsLoading, benefitsError] = useCollectionData(
    firestore.collection("benefits").where("isDeleted", "==", false) || null
  );

  const [processes = [], processesLoading, processesError] = useCollectionData(
    firestore.collection("processes").where("isDeleted", "==", false) || null
  );

  const [jobs = [], jobsLoading, jobsError] = useCollectionData(
    firestore.collection("jobs").where("isDeleted", "==", false) || null
  );

  const error =
    servicesError || usersError || benefitsError || processesError || jobsError;
  const loading =
    servicesLoading ||
    usersLoading ||
    benefitsLoading ||
    processesLoading ||
    jobsLoading;

  useEffect(() => {
    error && notification({ type: "error" });
  }, [error]);

  if (loading) return <Spinner height="100vh" />;

  return (
    <GlobalDataContext.Provider
      value={{
        users: orderBy(users, (user) => [user.createAt], ["asc"]),
        services: orderBy(services, (service) => [service.createAt], ["asc"]),
        benefits: orderBy(benefits, (benefit) => [benefit.createAt], ["asc"]),
        processes: orderBy(processes, (process) => [process.createAt], ["asc"]),
        jobs: orderBy(jobs, (job) => [job.createAt], ["asc"]),
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
};

export const useGlobalData = () => useContext(GlobalDataContext);
