import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FilterJobs } from "./FilterJobs";
import { CardJob } from "./CardJob";
import { useParams } from "react-router";
import { JobPreview } from "../ui";
import { mediaQuery } from "../../../styles";

export const OurJobs = ({ jobs = [], services = [] }) => {
  const { serviceId } = useParams();

  const [job, setJob] = useState(null);
  const [isModalJobPreviewOpen, setIsModalJobPreviewOpen] = useState(false);
  const [serviceType, setServiceType] = useState("diseno-y-desarrollo-web");

  const onSetJob = (job = null) => setJob(job);

  useEffect(() => {
    serviceId && setServiceType(serviceId);
  }, [serviceId]);

  useEffect(() => {
    viewJobs();
  }, [serviceType, serviceId]);

  const onHandleVisibleModal = () =>
    setIsModalJobPreviewOpen(!isModalJobPreviewOpen);

  const viewJobs = () =>
    jobs.filter((job) => job.service.nameId === serviceType);

  const onSetServiceType = (serviceTypeSelect) =>
    setServiceType(serviceTypeSelect);

  return (
    <>
      <Container>
        <h2>Trabajos</h2>
        <FilterJobs
          onSetServiceType={onSetServiceType}
          serviceType={serviceType}
          services={services}
        />
        <ul className="jobs-list">
          {viewJobs().map((job, index) => (
            <li
              className="job-item"
              key={index}
              onClick={() => {
                setJob(job);
                onHandleVisibleModal(job.id);
              }}
            >
              <CardJob job={job} />
            </li>
          ))}
        </ul>
      </Container>
      <JobPreview
        job={job}
        onSetJob={onSetJob}
        isModalJobPreviewOpen={isModalJobPreviewOpen}
        setIsModalJobPreviewOpen={setIsModalJobPreviewOpen}
      />
    </>
  );
};

const Container = styled.div`
  width: 93%;
  height: auto;
  margin: 4rem auto;
  border-radius: 2em;
  h2 {
    color: ${({ theme }) => theme.colors.primary};
    text-align: center;
    margin-bottom: 1em;
  }
  .jobs-list {
    display: grid;
    gap: 1.5em;
    grid-template-columns: repeat(1, 1fr);
    list-style: none;
    ${mediaQuery.minDesktop} {
      grid-template-columns: repeat(2, 1fr);
    }
    .job-item {
      width: 100%;
      height: 17em;
      ${mediaQuery.minTablet} {
        height: 22em;
      }
    }
  }
`;
