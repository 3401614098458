import React from "react";
import styled from "styled-components";
import { mediaQuery } from "../../../styles";
import { capitalize } from "lodash";

export const CardProcess = ({ number, name, description, image }) => {
  return (
    <Container>
      <div className="item-texts">
        <div className="item-number">0{number}</div>
        <div className="item-title">{name}</div>
        <div className="item-description">{capitalize(description)}</div>
      </div>
      <div className="item-image">
        {image && <img src={image} alt={name} />}
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, auto);
  gap: 1em;
  ${mediaQuery.minTablet} {
    grid-template-columns: 39% 1fr;
    grid-template-rows: auto auto;
  }
  .item-texts {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    .item-number {
      text-align: left;
      color: ${({ theme }) => theme.colors.primary};
      font-size: 5em;
      font-weight: 900;
      line-height: 1em;
    }
    .item-title {
      text-align: left;
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 1.8em;
      font-weight: bold;
      line-height: 1.2em;
      margin: 0.1em 0;
      text-transform: uppercase;
    }
    .item-description {
      text-align: left;
      font-family: "MontserratLight", serif;
    }
  }
  .item-image {
    display: flex;
    align-items: end;
    justify-content: center;
    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: contain;
      ${mediaQuery.minTablet} {
        max-width: 20em;
      }
    }
  }
`;
