import React from "react";
import styled from "styled-components";
import { capitalize } from "lodash";

export const CardJob = ({ job }) => {
  return (
    <Container>
      <div className="bg-gradient" />
      <div className="item-image">
        <img src={job?.photo?.url} alt={job.name} />
        <div className="item-title">
          <h4>{capitalize(job.name)}</h4>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 4px 8px 12px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 1.5em;
  border: 4px solid transparent;
  position: relative;
  transition: all ease-in-out 0.2s;
  &:hover {
    transition: all ease-in-out 0.2s;
    border: 4px solid ${({ theme }) => theme.colors.primary};
  }
  &:hover {
    transition: all ease-in-out 0.4s;
    .item-image img {
      transform: scale(1, 1);
    }
  }

  .bg-gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 300;
    filter: drop-shadow(4px 8px 12px rgba(0, 0, 0, 0.25));
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 43%,
      rgba(0, 0, 0, 0.88) 96%
    );
  }
  .item-image {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1.1, 1.1);
      transition: all ease-in-out 0.4s;
    }
    .item-title {
      position: absolute;
      bottom: 0.5em;
      left: 0.5em;
      right: 0;
      z-index: 700;
      padding: 0 1rem;
      h4 {
        color: #fff;
      }
    }
  }
`;
