import { Route, Routes } from "react-router-dom";
import { BaseLayout } from "../components";
import {
  AboutUs,
  Contact,
  Home,
  LegalNotices,
  Login,
  Page404,
  Service,
  Services,
  UserIntegration,
  Users,
} from "../pages";
import { ServiceIntegration, ServicesIntegration } from "../pages/admin";
import { AdminLayout } from "../components/admin/layout/AdminLayout";
import { ProcessesIntegration } from "../pages/admin/processes";
import { ProcessIntegration } from "../pages/admin/processes/_processId";
import { JobsIntegration } from "../pages/admin/jobs";
import { JobIntegration } from "../pages/admin/jobs/_jobId";
import { PrivateRoute } from "./PrivateRoute";

export const Router = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route
        exact
        path="/"
        element={
          <BaseLayout>
            <Home />
          </BaseLayout>
        }
      />
      <Route
        exact
        path="/services"
        element={
          <BaseLayout>
            <Services />
          </BaseLayout>
        }
      />
      <Route
        exact
        path="/services/:serviceId"
        element={
          <BaseLayout>
            <Service />
          </BaseLayout>
        }
      />
      <Route
        exact
        path="/about-us"
        element={
          <BaseLayout>
            <AboutUs />
          </BaseLayout>
        }
      />
      <Route
        exact
        path="/contact"
        element={
          <BaseLayout>
            <Contact />
          </BaseLayout>
        }
      />
      <Route exact path="/:legalNoticeId" element={<LegalNotices />} />
      <Route
        exact
        path="/admin"
        element={
          <PrivateRoute>
            <AdminLayout>
              <ServicesIntegration />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/admin/users"
        element={
          <PrivateRoute>
            <AdminLayout>
              <Users />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/admin/users/:userId"
        element={
          <PrivateRoute>
            <AdminLayout>
              <UserIntegration />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/admin/services"
        element={
          <PrivateRoute>
            <AdminLayout>
              <ServicesIntegration />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/admin/services/:serviceId"
        element={
          <PrivateRoute>
            <AdminLayout>
              <ServiceIntegration />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/admin/processes"
        element={
          <PrivateRoute>
            <AdminLayout>
              <ProcessesIntegration />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/admin/processes/:processId"
        element={
          <PrivateRoute>
            <AdminLayout>
              <ProcessIntegration />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/admin/jobs"
        element={
          <PrivateRoute>
            <AdminLayout>
              <JobsIntegration />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/admin/jobs/:jobId"
        element={
          <PrivateRoute>
            <AdminLayout>
              <JobIntegration />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      {/*<Route*/}
      {/*  exact*/}
      {/*  path="/admin/scripts"*/}
      {/*  element={*/}
      {/*    <PrivateRoute>*/}
      {/*      <AdminLayout>*/}
      {/*        <Scripts />*/}
      {/*      </AdminLayout>*/}
      {/*    </PrivateRoute>*/}
      {/*  }*/}
      {/*/>*/}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};
