import React, { useEffect, useState } from "react";
import { Modal } from "../Modal";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../Input";
import { Upload } from "../../Upload";
import { Button } from "../Button";
import { Form } from "../Form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormUtils } from "../../../../hooks";
import Title from "antd/lib/typography/Title";
import { TextArea } from "../TextArea";
import Card from "antd/lib/card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Image from "antd/lib/image";
import styled from "styled-components";

export const Benefit = ({
  open,
  service,
  onSubmitSaveService,
  onSetModal,
  onSetModalBenefit,
}) => {
  const [savingService, setSavingService] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);

  const schema = yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
    photo: yup.mixed().notRequired(),
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { required, error } = useFormUtils({ errors, schema });

  useEffect(() => {
    resetForm();
  }, [service]);

  const resetForm = () => {
    reset({
      name: service?.benefit?.name || "",
      description: service?.benefit?.description || "",
      photo: service?.benefit?.photo || null,
    });
  };

  const submitSaveBenefit = async (formData) => {
    try {
      setSavingService(true);

      const benefit = mapBenefit(formData);

      await onSubmitSaveService(benefit);
      onSetModal();
    } finally {
      setSavingService(false);
    }
  };

  const mapBenefit = (formData) => ({
    benefit: {
      name: formData.name.toLowerCase(),
      description: formData.description.toLowerCase(),
      photo: formData?.photo || service?.benefit.photo || null,
    },
  });

  return (
    <>
      <Card
        title="Beneficio"
        extra={
          <span className="pointer" onClick={() => onSetModalBenefit()}>
            <Button type="primary">
              <FontAwesomeIcon icon={faEdit} size="lg" />
              &nbsp; Edit
            </Button>
          </span>
        }
        bodyStyle={{ display: !service?.benefit ? "none" : "inherit" }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div className="d-flex">
              <TitleItem>
                <span>Titulo:</span> <strong> {service?.benefit?.name}</strong>
              </TitleItem>
            </div>
            <div>
              <span>Descripción:</span> <br />
              <strong> {service?.benefit?.description}</strong>
            </div>
          </Col>
          <Col span={24}>
            {service?.benefit?.photo && (
              <Image
                width={150}
                height={100}
                src={service?.benefit?.photo?.url}
              />
            )}
          </Col>
        </Row>
      </Card>
      <Modal
        closable
        open={open}
        onCancel={() => onSetModalBenefit()}
        title={<Title level={2}>Seccion beneficio</Title>}
      >
        <Form onSubmit={handleSubmit(submitSaveBenefit)}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, name } }) => (
                  <Input
                    label="Nombre"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={error(name)}
                    required={required(name)}
                  />
                )}
              />
            </Col>
            <Col xs={24} sm={24}>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, name } }) => (
                  <TextArea
                    label="Descripción"
                    rows={4}
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={error(name)}
                    required={required(name)}
                  />
                )}
              />
            </Col>
            <Col xs={24} sm={24}>
              <Controller
                name="photo"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <Upload
                    label="Foto de beneficio (980x650)"
                    accept="image/*"
                    name={name}
                    value={value}
                    filePath={`services/${service.id}/benefit`}
                    buttonText="Subir imagen"
                    error={error(name)}
                    required={required(name)}
                    onChange={(file) => onChange(file)}
                    onUploading={setUploadingImage}
                  />
                )}
              />
            </Col>
          </Row>
          <Row justify="end" gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8}>
              <Button
                type="default"
                size="large"
                block
                onClick={() => onSetModal()}
                disabled={uploadingImage | savingService}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                disabled={uploadingImage | savingService}
                loading={savingService}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const TitleItem = styled.div`
  display: flex;
  gap: 0.5em;
  font-size: 1em;
  text-transform: capitalize;
`;
