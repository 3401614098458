import React from "react";
import styled from "styled-components";
import { CardProcess } from "./CardProcess";
import { mediaQuery } from "../../../styles";

export const OurProcesses = ({ processes = [] }) => {
  return (
    <Container>
      <h2>Procesos</h2>
      <ul className="process-list">
        {processes.map((process, index) => (
          <li className="process-item" key={index}>
            <CardProcess
              number={process.number}
              name={process?.name}
              description={process?.description}
              image={process?.photo?.url}
            />
          </li>
        ))}
      </ul>
    </Container>
  );
};

const Container = styled.div`
  width: 93%;
  height: auto;
  margin: auto;
  padding: 3em 5em;
  background: ${({ theme }) => theme.colors.light};
  border-radius: 2em;
  h2 {
    color: ${({ theme }) => theme.colors.primary};
    text-align: center;
    margin-bottom: 1.3em;
  }
  .process-list {
    display: grid;
    gap: 4em;
    margin: 3rem 0;
    grid-template-columns: auto;
    list-style: none;
    ${mediaQuery.minMobile} {
      grid-template-columns: repeat(auto-fit, minmax(27em, auto));
    }
  }
`;
