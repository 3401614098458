import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import List from "antd/lib/list";
import Image from "antd/lib/image";
import Divider from "antd/lib/divider";
import Typography from "antd/lib/typography";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, IconAction, modalConfirm } from "../../../components/admin/ui";
import { useDefaultFirestoreProps, useDevice } from "../../../hooks";
import { useGlobalData } from "../../../providers";
import { useNavigate } from "react-router";
import styled, { css } from "styled-components";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";
import { firestore } from "../../../firebase";

const { Title, Text } = Typography;

export const ServicesIntegration = () => {
  const { isMobile } = useDevice();
  const navigate = useNavigate();
  const { assignDeleteProps } = useDefaultFirestoreProps();

  const { services } = useGlobalData();

  const navigateTo = (serviceId) => {
    const url = `/admin/services/${serviceId}`;

    navigate(url);
  };

  const onAddService = () => navigateTo("new");

  const onEditService = (service) => navigateTo(service.id);

  const onRemoveService = async (service) => {
    await firestore
      .collection("services")
      .doc(service.id)
      .update(assignDeleteProps(service));
  };

  const onConfirmRemoveService = (service) =>
    modalConfirm({
      content: "El service se eliminara",
      onOk: () => onRemoveService(service),
    });

  return (
    <Services
      isMobile={isMobile}
      services={services}
      onAddService={onAddService}
      onEditService={onEditService}
      onConfirmRemoveService={onConfirmRemoveService}
    />
  );
};

const Services = ({
  isMobile,
  services,
  onAddService,
  onEditService,
  onConfirmRemoveService,
}) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Button type="primary" onClick={() => onAddService()}>
          Agregar servicio
        </Button>
      </Col>
      <Divider />
      <Col span={24}>
        <Title level={3}>Servicios</Title>
      </Col>
      <Col span={24}>
        <List
          className="demo-loadmore-list"
          itemLayout={isMobile ? "vertical" : "horizontal"}
          dataSource={services}
          renderItem={(service) => (
            <List.Item
              actions={[
                <IconAction
                  key={service.id}
                  tooltipTitle="Editar"
                  icon={faEdit}
                  onClick={() => onEditService(service)}
                />,
                <IconAction
                  key={service.id}
                  tooltipTitle="Eliminar"
                  styled={{ color: (theme) => theme.colors.error }}
                  icon={faTrash}
                  onClick={() => onConfirmRemoveService(service)}
                />,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Image
                    src={
                      service?.servicePhoto?.thumbUrl ||
                      "https://firebasestorage.googleapis.com/v0/b/sendingemails-348505.appspot.com/o/resources%2Fimage-not-found.jpg?alt=media&token=35125bc7-a978-4ee0-8d01-d820b79b24b6"
                    }
                    width={150}
                    height={90}
                    style={{ objectFit: "contain" }}
                    alt="company logo"
                  />
                }
                title={
                  <Link to={`/admin/services/${service.id}`}>
                    <h4 className="link-color">{capitalize(service.name)}</h4>
                  </Link>
                }
                description={
                  <DescriptionWrapper color={service.color}>
                    <div className="item">
                      <Text strong>{service.category}</Text>
                    </div>
                  </DescriptionWrapper>
                }
              />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

const DescriptionWrapper = styled.div`
  ${({ color }) => css`
    display: grid;
    grid-row-gap: 0.3rem;
    justify-content: flex-start;
    .item {
      .item-text {
        color: ${({ theme }) => theme.colors.heading};
      }
    }
    .item-color {
      background: ${color};
    }
  `}
`;
