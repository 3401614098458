import React from "react";
import styled from "styled-components";
import { mediaQuery } from "../../../styles";
import { Dropdown, Menu, Space } from "antd";
import { capitalize, defaultTo } from "lodash";
import { LogoLight, LogoPrimary } from "../../../images";

export const HeaderMenu = ({
  user,
  onNavigate,
  menuList,
  isActiveLightTheme,
}) => {
  const subMenuList = (subMenu = []) =>
    subMenu.map((subMenu_, index) => ({
      key: index,
      label: (
        <div
          style={{
            width: "100%",
            height: "100%",
            padding: ".3em .5em",
          }}
          onClick={() => onNavigate(subMenu_.url)}
        >
          {capitalize(subMenu_.label)}
        </div>
      ),
      icon: subMenu_?.icon || "",
      disabled: subMenu_?.disabled || false,
    }));

  return (
    <WrapperMenuDesktop isActiveLightTheme={isActiveLightTheme}>
      <div className="content-left">
        <img
          src={isActiveLightTheme ? LogoLight : LogoPrimary}
          alt="logo IUBI"
          onClick={() => onNavigate(user ? "/admin" : "/")}
        />
      </div>
      <div className="content-right">
        <div className="principal-menu">
          {defaultTo(menuList, []).map((menu, index) => (
            <DropDownContainer
              key={index}
              overlay={
                menu?.subMenu ? (
                  <Menu key={index} items={subMenuList(menu.subMenu)} />
                ) : (
                  ""
                )
              }
            >
              <span
                style={{ cursor: "pointer", textTransform: "capitalize" }}
                onClick={() => onNavigate(menu.url)}
              >
                <span style={{ marginRight: ".5rem" }}>
                  {menu?.icon && menu.icon}
                </span>
                <Space>{capitalize(menu.label)}</Space>
              </span>
            </DropDownContainer>
          ))}
        </div>
      </div>
    </WrapperMenuDesktop>
  );
};

const DropDownContainer = styled(Dropdown)`
  .ant-dropdown-trigger {
    .ant-dropdown-menu-submenu-title {
      padding: 1.7rem 1rem !important;
    }
  }
`;

const WrapperMenuDesktop = styled.div`
  width: 100%;
  height: auto;
  color: ${({ isActiveLightTheme }) =>
    isActiveLightTheme ? "#fff" : "#1d1d1d"};
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-column-gap: 3rem;
  justify-content: space-between;
  font-family: "Gilroy-Medium", serif;
  ${mediaQuery.minHighResolution} {
    grid-column-gap: 7rem;
  }
  .content-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: inherit;
    img {
      width: auto;
      height: 4em;
      cursor: pointer;
    }
  }
  .content-right {
    .principal-menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: auto;
      height: 100%;
      clip-path: polygon(2.7rem 0, 100% 0%, 100% 100%, 0% 100%);
      margin-top: -1px;
      color: inherit;
      gap: 2rem;
      span {
        display: flex;
        align-items: center;
        justify-content: start;
        .item-icon {
          fill: ${({ theme }) => theme.colors.font1};
        }
      }
    }
  }
`;
