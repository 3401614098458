import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import List from "antd/lib/list";
import Image from "antd/lib/image";
import Divider from "antd/lib/divider";
import Typography from "antd/lib/typography";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, IconAction, modalConfirm } from "../../../components/admin/ui";
import { useDefaultFirestoreProps, useDevice } from "../../../hooks";
import { useGlobalData } from "../../../providers";
import { useNavigate } from "react-router";
import styled, { css } from "styled-components";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";
import { firestore } from "../../../firebase";

const { Title, Text } = Typography;

export const JobsIntegration = () => {
  const { isMobile } = useDevice();
  const navigate = useNavigate();
  const { assignDeleteProps } = useDefaultFirestoreProps();

  const { jobs } = useGlobalData();

  const navigateTo = (jobId) => {
    const url = `/admin/jobs/${jobId}`;

    navigate(url);
  };

  const onAddJob = () => navigateTo("new");

  const onEditJob = (job) => navigateTo(job.id);

  const onRemoveJob = async (job) => {
    await firestore
      .collection("jobs")
      .doc(job.id)
      .update(assignDeleteProps(job));
  };

  const onConfirmRemoveJob = (job) =>
    modalConfirm({
      content: "El job se eliminara",
      onOk: () => onRemoveJob(job),
    });

  return (
    <Jobs
      isMobile={isMobile}
      jobs={jobs}
      onAddJob={onAddJob}
      onEditJob={onEditJob}
      onConfirmRemoveJob={onConfirmRemoveJob}
    />
  );
};

const Jobs = ({ isMobile, jobs, onAddJob, onEditJob, onConfirmRemoveJob }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Button type="primary" onClick={() => onAddJob()}>
          Agregar job
        </Button>
      </Col>
      <Divider />
      <Col span={24}>
        <Title level={3}>Jobs</Title>
      </Col>
      <Col span={24}>
        <List
          className="demo-loadmore-list"
          itemLayout={isMobile ? "vertical" : "horizontal"}
          dataSource={jobs}
          renderItem={(job) => (
            <List.Item
              actions={[
                <IconAction
                  key={job.id}
                  tooltipTitle="Editar"
                  icon={faEdit}
                  onClick={() => onEditJob(job)}
                />,
                <IconAction
                  key={job.id}
                  tooltipTitle="Eliminar"
                  styled={{ color: (theme) => theme.colors.error }}
                  icon={faTrash}
                  onClick={() => onConfirmRemoveJob(job)}
                />,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Image
                    src={
                      job?.photo?.url ||
                      "https://firebasestorage.googleapis.com/v0/b/sendingemails-348505.appspot.com/o/resources%2Fimage-not-found.jpg?alt=media&token=35125bc7-a978-4ee0-8d01-d820b79b24b6"
                    }
                    width={150}
                    height={90}
                    style={{ objectFit: "contain" }}
                    alt="company logo"
                  />
                }
                title={
                  <Link to={`/admin/jobs/${job.id}`}>
                    <h4 className="link-color">{capitalize(job.name)}</h4>
                  </Link>
                }
                description={
                  <DescriptionWrapper color={job.color}>
                    <div className="item">
                      <Text strong>{capitalize(job?.service?.name || "")}</Text>
                    </div>
                  </DescriptionWrapper>
                }
              />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

const DescriptionWrapper = styled.div`
  ${({ color }) => css`
    display: grid;
    grid-row-gap: 0.3rem;
    justify-content: flex-start;
    .item {
      .item-text {
        color: ${({ theme }) => theme.colors.heading};
      }
    }
    .item-color {
      background: ${color};
    }
  `}
`;
