import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router";
import { BannerService } from "../../../images";
import {
  Button,
  ModalContact,
  HelmetComponent,
  OurJobs,
  OurProcesses,
} from "../../../components";
import { mediaQuery } from "../../../styles";
import { useGlobalData } from "../../../providers";
import { capitalize, orderBy } from "lodash";
import { Spinner } from "../../../components/admin/ui";

export const Service = () => {
  const { serviceId } = useParams();
  const navigate = useNavigate();
  const { services, jobs } = useGlobalData();
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState(null);

  const onGoBack = () => navigate(-1);

  useEffect(() => {
    getService();
  }, [serviceId, services]);

  const getService = () => {
    try {
      setLoading(true);

      const service =
        services.find((service) => service.nameId === serviceId) || null;

      if (!service) return onGoBack();

      setService({ ...service });
    } finally {
      setLoading(false);
    }
  };

  const onNavigateTo = (pathname) => navigate(pathname);

  const handleVisibleContactForm = () =>
    setIsModalContactOpen(!isModalContactOpen);

  const [isModalContactOpen, setIsModalContactOpen] = useState(false);

  if (loading) return <Spinner height="70vh" />;

  if (!service) return onNavigateTo("/");

  return (
    <>
      <HelmetComponent
        key={service.nameId}
        config={{
          title: `IUBI - ${capitalize(service.name)}`,
          url: `https://iubi.pe/${service.nameId}`,
          image:
            "https://iubi.pe/static/media/banner-1.38fd62cfff3d2b466fa9.webp",
        }}
      />
      <Container>
        <WrapperSlide bgSlide={service?.coverPhoto?.thumbUrl || BannerService}>
          <div className="wrapper-items">
            <h3 className="item-title">{service?.name}</h3>
            <Button
              type="primary"
              className="btn-contact"
              onClick={() => handleVisibleContactForm(true)}
            >
              Quiero que me contácten
            </Button>
          </div>
        </WrapperSlide>
        <SectionBenefit>
          <div className="item-left">
            <div className="title-item">
              {capitalize(service?.benefit?.name)}
            </div>
            <div className="description-item">
              {capitalize(service?.benefit?.description)}
            </div>
          </div>
          <div className="item-right">
            {service?.benefit?.photo && (
              <img
                src={service.benefit.photo.url}
                alt={service.benefit?.name}
              />
            )}
          </div>
        </SectionBenefit>
        <OurProcesses
          processes={orderBy(service?.processes || [], ["number", "asc"])}
        />
        <OurJobs jobs={jobs} services={services} />
        <ModalContact
          isModalContactOpen={isModalContactOpen}
          handleOk={handleVisibleContactForm}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const WrapperSlide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 70vh;
  background-blend-mode: multiply;
  background: url(${({ bgSlide }) => bgSlide}),
    rgba(255, 255, 255, 0.8) no-repeat center center;
  background-size: cover;
  border-radius: 2em;
  overflow: hidden;
  margin: 1em auto;
  position: relative;

  ${mediaQuery.minTablet} {
    height: 60vh;
  }

  .wrapper-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2em;

    .item-title {
      color: ${({ theme }) => theme.colors.primary};
      text-shadow: -1px 1px 2px #000000;
      font-size: 3em;
      text-align: center;
      margin: 0;
      text-transform: uppercase;

      ${mediaQuery.minTablet} {
        font-size: 4em;
      }
    }

    .btn-contact {
      margin-top: 3em;
      display: flex;
      justify-content: center;
      z-index: 200;
    }
  }
`;

const SectionBenefit = styled.div`
  border-radius: 2em;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 27em;
  background: ${({ theme }) => theme.colors.primary};
  margin: 3em 1em;
  overflow: hidden;
  ${mediaQuery.minTablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
  .item-left {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    color: ${({ theme }) => theme.colors.white};
    padding: 3em;
    ${mediaQuery.minTablet} {
      padding: 3em 6em;
    }
    .title-item {
      font-size: 2em;
      font-weight: 600;
      margin-bottom: 0.5em;
      text-align: left;
    }
  }
  .item-right {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
