import React from "react";
import DrawerAntd from "antd/lib/drawer";
import styled from "styled-components";
import { Menu } from "antd";
import { useNavigate } from "react-router";
import { capitalize } from "lodash";

export const Drawer = ({ visibleDrawer, onSetVisibleDrawer, menuList }) => {
  const navigate = useNavigate();

  const onNavigate = (keyPath) => navigate(keyPath);

  const getItem = ({ label, key, icon, children, type }) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };

  const items = menuList.map((menu) =>
    getItem({
      key: menu.key,
      label: capitalize(menu.label),
      icon: menu.icon,
      ...(menu.subMenu && {
        children: (menu.subMenu || []).map((subMenu_) =>
          getItem({
            key: subMenu_.key,
            label: capitalize(subMenu_.label),
            icon: subMenu_.icon,
          })
        ),
      }),
    })
  );

  const onClick = (e) => {
    onSetVisibleDrawer(false);
    onNavigate(e.key);
  };

  return (
    <ComponentDrawerAntd
      title={null}
      placement="right"
      onClose={() => onSetVisibleDrawer(false)}
      visible={visibleDrawer}
    >
      <MenuItem onClick={onClick} mode="inline" items={items} />
    </ComponentDrawerAntd>
  );
};

const ComponentDrawerAntd = styled(DrawerAntd)`
  .ant-drawer-header {
    .ant-drawer-close {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  .ant-drawer-header {
    border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary};
    .ant-drawer-header-title {
      justify-content: end;
    }
  }

  .ant-drawer-wrapper-body .ant-drawer-body {
    background: ${({ theme }) => theme.colors.primary} !important;
  }

  .ant-drawer-header,
  .ant-drawer-content {
    background: ${({ theme }) => theme.colors.primary};
  }
`;

const MenuItem = styled(Menu)`
  width: 100% !important;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.primary};
  border: none;

  .item-icon {
    fill: ${({ theme }) => theme.colors.white};
  }

  .ant-menu-sub.ant-menu-inline {
    color: inherit;
    background: inherit;
  }

  .ant-menu-submenu-arrow {
    color: inherit;
  }

  &:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #ffffff08 !important;
  }

  :where(.css-dev-only-do-not-override-ixblex).ant-menu-light
    .ant-menu-item-selected {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
