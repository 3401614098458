import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import List from "antd/lib/list";
import Image from "antd/lib/image";
import Divider from "antd/lib/divider";
import Typography from "antd/lib/typography";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, IconAction, modalConfirm } from "../../../components/admin/ui";
import { useDefaultFirestoreProps, useDevice } from "../../../hooks";
import { useGlobalData } from "../../../providers";
import { useNavigate } from "react-router";
import styled, { css } from "styled-components";
import { capitalize, orderBy } from "lodash";
import { Link } from "react-router-dom";
import { firestore } from "../../../firebase";

const { Title, Text } = Typography;

export const ProcessesIntegration = () => {
  const { isMobile } = useDevice();
  const navigate = useNavigate();
  const { assignDeleteProps } = useDefaultFirestoreProps();

  const { processes } = useGlobalData();

  const navigateTo = (processId) => {
    const url = `/admin/processes/${processId}`;

    navigate(url);
  };

  const onAddProcess = () => navigateTo("new");

  const onEditProcess = (process) => navigateTo(process.id);

  const onRemoveProcess = async (process) => {
    await firestore
      .collection("processes")
      .doc(process.id)
      .update(assignDeleteProps(process));
  };

  const onConfirmRemoveProcess = (process) =>
    modalConfirm({
      content: "El process se eliminara",
      onOk: () => onRemoveProcess(process),
    });

  const processesView = orderBy(processes, ["number", "asc"]);

  return (
    <Processes
      isMobile={isMobile}
      processes={processesView}
      onAddProcess={onAddProcess}
      onEditProcess={onEditProcess}
      onConfirmRemoveProcess={onConfirmRemoveProcess}
    />
  );
};

const Processes = ({
  isMobile,
  processes,
  onAddProcess,
  onEditProcess,
  onConfirmRemoveProcess,
}) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Button type="primary" onClick={() => onAddProcess()}>
          Agregar proceso
        </Button>
      </Col>
      <Divider />
      <Col span={24}>
        <Title level={3}>Procesos</Title>
      </Col>
      <Col span={24}>
        <List
          className="demo-loadmore-list"
          itemLayout={isMobile ? "vertical" : "horizontal"}
          dataSource={processes}
          renderItem={(process) => (
            <List.Item
              actions={[
                <IconAction
                  key={process.id}
                  tooltipTitle="Editar"
                  icon={faEdit}
                  onClick={() => onEditProcess(process)}
                />,
                <IconAction
                  key={process.id}
                  tooltipTitle="Eliminar"
                  styled={{ color: (theme) => theme.colors.error }}
                  icon={faTrash}
                  onClick={() => onConfirmRemoveProcess(process)}
                />,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Image
                    src={
                      process?.photo?.url ||
                      "https://firebasestorage.googleapis.com/v0/b/sendingemails-348505.appspot.com/o/resources%2Fimage-not-found.jpg?alt=media&token=35125bc7-a978-4ee0-8d01-d820b79b24b6"
                    }
                    width={150}
                    height={90}
                    style={{ objectFit: "contain" }}
                    alt="company logo"
                  />
                }
                title={
                  <Link to={`/admin/processes/${process.id}`}>
                    <h4 className="link-color">
                      {process.number}: {capitalize(process.name)}
                    </h4>
                  </Link>
                }
                description={
                  <DescriptionWrapper color={process.color}>
                    <div className="item">
                      <Text strong>{process.description}</Text>
                    </div>
                  </DescriptionWrapper>
                }
              />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

const DescriptionWrapper = styled.div`
  ${({ color }) => css`
    display: grid;
    grid-row-gap: 0.3rem;
    justify-content: flex-start;
    .item {
      .item-text {
        color: ${({ theme }) => theme.colors.heading};
      }
    }
    .item-color {
      background: ${color};
    }
  `}
`;
