import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { LogoLight, LogoPrimary } from "../../../images";
import { mediaQuery } from "../../../styles";

export const HeaderMenuMobile = ({
  user,
  onSetVisibleDrawer,
  onNavigate,
  isActiveLightTheme,
}) => {
  return (
    <WrapperMenuMobile isActiveLightTheme={isActiveLightTheme}>
      <div className="content-left">
        <img
          src={isActiveLightTheme ? LogoLight : LogoPrimary}
          alt="logo IUBI"
          onClick={() => onNavigate(user ? "/admin" : "/")}
        />
      </div>
      <div className="content-right">
        <div className="icon-bar" onClick={() => onSetVisibleDrawer(true)}>
          <FontAwesomeIcon icon={faBars} />
        </div>
      </div>
    </WrapperMenuMobile>
  );
};

const WrapperMenuMobile = styled.div`
  color: ${({ isActiveLightTheme }) =>
    isActiveLightTheme ? "#fff" : "#1d1d1d"};
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  justify-content: space-between;
  font-family: "Gilroy-Medium", serif;

  .content-left {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    font-size: 0.9rem;
    ${mediaQuery.minTablet} {
      font-size: 1rem;
    }
    img {
      width: auto;
      height: 2.6em;
      cursor: pointer;
      ${mediaQuery.minMobile} {
        height: 5.1em;
      }
    }
  }

  .content-right {
    display: flex;
    justify-content: end;

    .icon-bar {
      width: auto;
      height: 100%;
      padding: 0.7rem 0;
      font-size: 1.8em;
      ${mediaQuery.minMobile} {
        font-size: 3.7em;
      }
      ${mediaQuery.minDesktop} {
        font-size: 2.5em;
      }
    }
  }
`;
