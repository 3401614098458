import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";

export const CardService = ({ name, image, url }) => {
  const navigate = useNavigate();

  const onNavigateToService = (pathname) => navigate(pathname);

  return (
    <Container onClick={() => onNavigateToService(url)}>
      <div className="item-image">
        <img src={image} alt={name} />
      </div>
      <div className="item-footer">
        <h4>{name}</h4>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 12rem 4rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  overflow: hidden;
  background: #ffffff;
  box-shadow: 4px 8px 12px rgba(0, 0, 0, 0.25);
  border-radius: 2em;
  cursor: pointer;
  transform: scale(1);
  transition: all ease-in-out 0.3s;
  &:hover {
    transition: all ease-in-out 0.3s;
    transform: scale(1.1);
  }
  .item-image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .item-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.5em 0;
    h4 {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 700;
      font-size: 1em;
      text-transform: capitalize;
    }
  }
`;
