import { Router } from "./router";
import { yup } from "./config";
import { setLocale } from "yup";
import {
  AuthenticationProvider,
  ConfigsInitializer,
  GlobalDataProvider,
  VersionProvider,
} from "./providers";

const App = () => {
  // const googleAnalytics = currentConfig.googleAnalytics;

  // useEffect(() => {
  //   ReactGA.initialize(googleAnalytics.trackingId);
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, [googleAnalytics.trackingId]);

  setLocale(yup["es"]);

  return (
    <VersionProvider>
      <ConfigsInitializer>
        <AuthenticationProvider>
          <GlobalDataProvider>
            <Router />
          </GlobalDataProvider>
        </AuthenticationProvider>
      </ConfigsInitializer>
    </VersionProvider>
  );
};

export default App;
