import React from "react";

export const TermsAndConditions = () => {
  return (
    <div>
      <h1 className="text-center">Términos y Condiciones</h1>
      <p>
        El sitio web <strong>https://iubi.pe</strong>, es una web cuya propiedad
        es la empresa IUBI COMPANY S.A.C. identificad con RUC{" "}
        <strong>2020202020</strong> y representada por SERGIO DANIEL LIZANO
        INGA, identificado con DNI 75519745 en adelante IUBI, y en este aviso
        legal de términos y condiciones de uso quiero explicarte cómo se regulan
        el acceso, navegación y utilización del presente Sitio Web, sin
        perjuicio que me reserve el derecho a modificar la presentación, la
        configuración, contenido del mismo, así como las condiciones requeridas
        para el acceso y uso de https://iubi.pe
      </p>
      <p>
        El acceso y uso de los contenidos de https://iubi.pe después de la
        entrada en vigor de sus modificaciones o cambios suponen la aceptación
        de los mismos, es decir, el acceso, navegación y utilización del sitio
        web, implica la aceptación expresa y sin reservas de todos los términos
        y condiciones, teniendo la misma validez y eficacia que cualquier
        contrato celebrado por escrito y/o firmado.
      </p>
      <p>
        Su observación y cumplimiento es exigible respecto a cualquier persona
        que acceda, navegue o utilice nuestra web. Si no estás de acuerdo con
        los términos expuestos, no accedas, navegues o utilices este sitio web.
      </p>
      <p>
        Los presentes términos y condiciones de uso regulan el acceso,
        navegación y utilización de IUBI, sin perjuicio que nos reserve el
        derecho a modificar la presentación, la configuración y el contenido del
        mismo, así como las condiciones requeridas para su acceso y uso. El
        acceso y uso de los contenidos de IUBI después de la entrada en vigor de
        sus modificaciones o cambios suponen la aceptación de los mismos.
      </p>
      <p>
        El acceso a IUBI es gratuito, así como la adquisición de los productos
        ofrecidos a cambio de la entrega de los datos solicitados en los
        formularios de IUBI, excepto en lo relativo al coste de la conexión a
        través de la red de telecomunicaciones suministrada por el proveedor de
        acceso contratado por IUBI y los productos en los que se indica el
        precio correspondiente para su adquisición.
      </p>
      <h4>USUARIOS</h4>
      <p>
        A los efectos de los presentes términos y condiciones, vamos a
        considerar que es un usuario: Toda aquella persona que navega por el
        sitio web, accediendo a cualquiera de sus secciones, páginas, landing
        page, multimedia, etc;
      </p>
      <p>
        A los efectos de los presentes términos y condiciones, vamos a
        considerar que es un usuario: Toda aquella persona que navega por el
        sitio web, accediendo a cualquiera de sus secciones, páginas, landing
        page, multimedia, etc;
      </p>
      <p>
        Así como cualquiera que sea receptor de la información enviada mediante
        correo electrónico, ya sean: plantillas, ebooks, artículos, productos
        con links de afiliado, etc;
      </p>
      <p>
        Desde el momento que el usuario comience la navegación, se establece una
        relación en donde acepta los términos y condiciones aquí expuestas.
        Igualmente, aquel usuario que se suscriba en cualquiera de sus
        formularios de la web, luego de dada su confirmación por email, declara
        aceptar estos términos y condiciones y me comprometo a respetar su
        privacidad, y no usar sus datos para fines que no estén especificados en
        el presente aviso legal.
      </p>
      <h4>CLIENTES</h4>
    </div>
  );
};
