import React from "react";
import styled from "styled-components";
import { Button } from "../ui";
import { capitalize } from "lodash";

export const FilterJobs = ({ onSetServiceType, serviceType, services }) => {
  const isSelectService = (categorySelect) =>
    serviceType === categorySelect ? "tertiary" : "primary";

  return (
    <Container>
      <ul>
        {services.map((service, index) => (
          <li key={index}>
            <Button
              type={isSelectService(service.nameId)}
              margin="0"
              borderRadius="10px"
              onClick={() => onSetServiceType(service.nameId)}
            >
              {capitalize(service.name)}
            </Button>
          </li>
        ))}
      </ul>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    li {
      margin: 0.5em;
      font-family: "MontserratLight", serif;
    }
  }
`;
