import React from "react";
import Modal from "antd/lib/modal/Modal";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { ContactUs } from "./ContactUs";
import { capitalize, isEmpty } from "lodash";

export const JobPreview = ({
  job,
  onSetJob,
  isModalJobPreviewOpen,
  setIsModalJobPreviewOpen,
}) => {
  return (
    <ModalContainer
      title={null}
      open={isModalJobPreviewOpen}
      onCancel={() => {
        onSetJob(null);
        return setIsModalJobPreviewOpen(!isModalJobPreviewOpen);
      }}
      footer={false}
      header={false}
      closeIcon={<FontAwesomeIcon icon={faXmark} color="black" size="lg" />}
    >
      <div className="wrapper-items">
        <div className="item-logo">
          <img src={job?.clientLogo?.url} alt="client logo" />
        </div>
        <div className="text-items">
          <h4>{capitalize(job?.name)}</h4>
          <p>{capitalize(job?.description)}</p>
        </div>
      </div>
      <div className="wrapper-items">
        <div className="wrapper-items-card">
          <div className="item-card">
            <div className="top-item">
              <h4>Reto</h4>
              <p>{job?.challenge?.description}</p>
            </div>
            <div className="bottom-item">
              <img
                src={job?.challenge?.photo?.url}
                alt={job?.challenge?.name}
              />
            </div>
          </div>
          <div className="item-card">
            <div className="top-item">
              <h4>Solucion</h4>
              <p>{job?.solution?.description}</p>
            </div>
            <div className="bottom-item">
              <img src={job?.solution?.photo?.url} alt={job?.challenge?.name} />
            </div>
          </div>
        </div>
      </div>
      {!isEmpty(job?.processes) && (
        <div className="wrapper-items process-content">
          <h3>Procesos</h3>
          {(job?.processes || []).map((process) => (
            <div className="item-process">
              <h4 className="item-title">{process?.name}</h4>
              <div className="item-img">
                <img src={process?.photo?.url} alt={process?.name} />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="wrapper-items">
        <ContactUs />
      </div>
    </ModalContainer>
  );
};

const ModalContainer = styled(Modal)`
  background: ${({ theme }) => theme.colors.white} !important;
  color: ${({ theme }) => theme.colors.white};
  z-index: 9999;
  position: relative;
  padding-bottom: 0;
  height: auto;

  .wrapper-items {
    .item-logo {
      display: flex;
      justify-content: center;
      img {
        width: 10em;
        height: auto;
      }
    }
    .text-items {
      margin: 2.5em 1em;
      text-align: center;
      color: ${({ theme }) => theme.colors.font1};
    }
  }

  .wrapper-items-card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16em, auto));
    gap: 1em;
    .item-card {
      display: grid;
      grid-template-rows: 1fr 17em;
      border: 1px solid #eee;
      border-radius: 1em;
      position: relative;
      overflow: hidden;
      .top-item {
        padding: 1em;
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.primary};
        h4 {
          color: ${({ theme }) => theme.colors.white};
        }
      }
      .bottom-item {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .process-content {
    h3 {
      color: ${({ theme }) => theme.colors.primary};
      margin: 1em 0 0.5em 0;
    }

    .item-process:nth-child(3n) {
      h4 {
        text-align: right;
      }
    }

    .item-process {
      h4 {
        margin: 2em 0 0.5em 0;
        text-align: left;
        color: ${({ theme }) => theme.colors.primary};
        text-transform: uppercase;
      }
      .item-img {
        width: 100%;
        height: 17em;
        border-radius: 1em;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
`;
