import React, { useState } from "react";
import styled from "styled-components";
import {
  Carousel,
  ContactUs,
  ModalContact,
  OurJobs,
  OurProcesses,
  ServicesSection,
} from "../../components";
import {
  Banner1,
  Banner2,
  Banner3,
  Banner4,
  Banner5,
  BannerMb1,
  BannerMb2,
  BannerMb3,
  BannerMb4,
  BannerMb5,
} from "../../images";
import { useDevice } from "../../hooks";
import { useGlobalData } from "../../providers";
import { useNavigate } from "react-router";
import { orderBy } from "lodash";
import { Helmet } from "react-helmet";

const bannersCarousel = [Banner1, Banner2, Banner3, Banner4, Banner5];
const bannersCarouselMobile = [
  BannerMb1,
  BannerMb2,
  BannerMb3,
  BannerMb4,
  BannerMb5,
];

export const Home = () => {
  const { isMobile } = useDevice();
  const navigate = useNavigate();
  const { services, processes, jobs } = useGlobalData();

  const [isModalContactOpen, setIsModalContactOpen] = useState(false);

  const onHandleVisibleContactForm = () =>
    setIsModalContactOpen(!isModalContactOpen);

  const onNavigateTo = (pathname) => navigate(pathname);

  // const gaEventTracker = useAnalyticsEventTracker("Button");

  // const contactClickHandler = (action, label) => gaEventTracker(action, label);

  const processesView = orderBy(processes, ["number", "asc"]);

  return (
    <>
      <Helmet>
        <title data-react-helmet="true">
          IUBI - Desarrollo web y marketing digital efectiva
        </title>
      </Helmet>
      <Container>
        <Carousel images={isMobile ? bannersCarouselMobile : bannersCarousel} />
        <ServicesSection services={services} onNavigateTo={onNavigateTo} />
        <OurProcesses processes={processesView} />
        <OurJobs jobs={jobs} services={services} />
        <ContactUs
          title="¿Listo para llevar clientes a tu empresa?"
          buttonText="Quiero empezar"
        />
        <ModalContact
          isModalContactOpen={isModalContactOpen}
          handleOk={onHandleVisibleContactForm}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
`;
