import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const defaultConfig = {
  title: "IUBI - Desarrollo web y marketing digital efectiva",
  description:
    "Transformamos tu visión en una realidad digital, con estrategias innovadoras y resultados comprobados.",
  url: "https://iubi.pe",
  image: "https://iubi.pe/static/media/banner-1.38fd62cfff3d2b466fa9.webp",
};

export const HelmetComponent = ({ config }) => {
  const [configHelmet, setConfigHelmet] = useState(defaultConfig);

  useEffect(() => setConfigHelmet(config), []);

  return (
    <Helmet>
      <title data-react-helmet="true">{configHelmet.title}</title>
      <meta property="og:type" content="website" />
      <meta
        data-react-helmet="true"
        name="description"
        content={configHelmet.description}
      />

      <meta property="og:title" content={configHelmet.title} />
      <meta property="og:site_name" content="https://iubi.pe" />
      <meta
        property="og:description"
        content={configHelmet.description}
        data-react-helmet="true"
      />
      <meta
        property="og:url"
        content={configHelmet.url}
        data-react-helmet="true"
      />
      <meta
        property="og:image"
        content={configHelmet.image}
        data-react-helmet="true"
      />

      <meta property="og:image" content={configHelmet.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:locale" content="es_ES" data-react-helmet="true" />
      <meta
        property="og:image:user_generated"
        content="false"
        data-react-helmet="true"
      />
      <meta property="og:image:width" content="640" data-react-helmet="true" />
    </Helmet>
  );
};
