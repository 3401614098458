import React from "react";
import Modal from "antd/lib/modal/Modal";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FormContact } from "./FormContact";

export const ModalContact = ({ isModalContactOpen, handleOk }) => {
  return (
    <ModalContainer
      title={null}
      open={isModalContactOpen}
      onCancel={() => handleOk()}
      footer={false}
      header={false}
      closeIcon={<FontAwesomeIcon icon={faXmark} color="white" size="lg" />}
    >
      <h4>COMENCEMOS TU PROYECTO</h4>
      <FormContact
        isModalContactOpen={isModalContactOpen}
        handleOk={handleOk}
      />
    </ModalContainer>
  );
};

const ModalContainer = styled(Modal)`
  background: ${({ theme }) => theme.colors.secondary} !important;
  color: ${({ theme }) => theme.colors.white};
  z-index: 9999;
  position: relative;
  padding-bottom: 0;
  border-radius: 2em;
  .ant-modal-content {
    background: ${({ theme }) => theme.colors.secondary} !important;
    border-radius: 2em;
  }
  h4 {
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 2em;
    margin: 1em auto;
  }
  .item-checkbox {
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    label {
      color: inherit;
      line-height: 1.2em;
    }
  }
  .strong-description {
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.2em;
    line-height: 1.2em;
  }
`;
