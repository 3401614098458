import React from "react";
import { HelmetComponent } from "../../components";
import styled from "styled-components";
import { useParams } from "react-router";
import { legalNotices } from "../../data-list";
import { LogoPrimary } from "../../images";
import { Link } from "react-router-dom";
import { capitalize } from "lodash";
import { mediaQuery } from "../../styles";

export const LegalNotices = () => {
  const { legalNoticeId } = useParams();

  const document = legalNotices[legalNoticeId];

  return (
    <Container>
      <HelmetComponent
        config={{
          title: `IUBI - ${document?.title}`,
          url: `https://iubi.pe/${legalNoticeId}`,
          image:
            "https://iubi.pe/static/media/banner-1.38fd62cfff3d2b466fa9.webp",
        }}
      />
      <div className="header-document">
        <Link to="/">
          <img src={LogoPrimary} alt="logo iubi" />
        </Link>
      </div>
      <div className="body-document">{document?.component}</div>
      <div className="footer-document">
        <ul className="legal-list">
          {Object.entries(legalNotices).map((legalNotice, index) => (
            <li key={index}>
              <Link to={`/${legalNotice[0]}`}>
                {capitalize(legalNotice[0])}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 2em 0;

  .header-document {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    padding: 1.3em 0;
    img {
      object-fit: contain;
      width: 13em;
      height: auto;
    }
  }
  .body-document {
    padding: 1em 0;
    position: relative;
    width: 100%;
    max-width: 95%;
    margin: auto;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
      Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    ${mediaQuery.minDesktop} {
      max-width: 800px;
    }

    .text {
      &-center {
        text-align: center;
      }
      &-left {
        text-align: left;
      }
      &-right {
        text-align: right;
      }
      &-upper {
        text-transform: uppercase;
      }
    }

    h1 {
      font-size: 2.5em;
    }
  }

  .footer-document {
    width: 100%;
    height: auto;
    padding: 1.3em 0;
    .legal-list {
      list-style: none;
      display: flex;
      justify-content: start;
      flex-direction: column;
      ${mediaQuery.minDesktop} {
        flex-direction: row;
        justify-content: center;
      }
      li {
        margin: 0.7em;
      }
    }
  }
`;
