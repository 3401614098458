import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { useNavigate, useParams } from "react-router";
import Title from "antd/lib/typography/Title";
import {
  Button,
  Form,
  Input,
  notification,
  Select,
  TextArea,
} from "../../../../components/admin/ui";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDefaultFirestoreProps, useFormUtils } from "../../../../hooks";
import { Upload } from "../../../../components/admin";
import { firestore } from "../../../../firebase";
import { useGlobalData } from "../../../../providers";
import { assign, capitalize } from "lodash";
import Card from "antd/lib/card/Card";
import { ProcessesList } from "./ProcessesList";

export const JobIntegration = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const { assignCreateProps, assignUpdateProps } = useDefaultFirestoreProps();

  const { services, jobs } = useGlobalData();

  const [job, setJob] = useState({});
  const [savingJob, setSavingJob] = useState(false);

  useEffect(() => {
    const _job =
      jobId === "new"
        ? { id: firestore.collection("jobs").doc().id }
        : jobs.find((job) => job.id === jobId);

    if (!_job) return navigate(-1);

    setJob(_job);
  }, []);

  const onSubmitSaveJob = async (formData) => {
    try {
      setSavingJob(true);

      await firestore
        .collection("jobs")
        .doc(job.id)
        .set(
          jobId === "new"
            ? assignCreateProps(mapJob(job, formData))
            : assignUpdateProps(mapJob(job, formData)),
          { merge: true }
        );

      notification({ type: "success" });

      onGoBack();
    } catch (e) {
      console.log("ErrorSaveJob: ", e);
      notification({ type: "error" });
    } finally {
      setSavingJob(false);
    }
  };

  const mapJob = (job, formData) =>
    assign(
      {},
      {
        id: job.id,
        name: formData.name.toLowerCase(),
        photo: formData.photo,
        service:
          services.find((service) => service.id === formData.serviceId) || null,
        clientLogo: formData.clientLogo,
        description: formData.description,
        challenge: formData.challenge,
        solution: formData.solution,
        processes: formData.processes.map((process) => ({
          name: process?.name || "",
          description: process?.description || "",
          photo: process?.photo || null,
        })),
      }
    );

  const onGoBack = () => navigate(-1);

  return (
    <Job
      job={job}
      services={services}
      onSubmitSaveJob={onSubmitSaveJob}
      onGoBack={onGoBack}
      savingJob={savingJob}
    />
  );
};

const Job = ({ job, services, onSubmitSaveJob, savingJob, onGoBack }) => {
  const [uploadingImage, setUploadingImage] = useState(false);

  const schema = yup.object({
    name: yup.string().required(),
    photo: yup.mixed().required(),
    serviceId: yup.string().required(),
    clientLogo: yup.mixed().nullable().required(),
    description: yup.string().required(),
    challenge: yup
      .object({
        description: yup.string().nullable().required(),
        photo: yup.mixed().required(),
      })
      .nullable()
      .required(),
    solution: yup
      .object({
        description: yup.string().nullable().required(),
        photo: yup.mixed().required(),
      })
      .nullable()
      .required(),
    processes: yup.array().required(),
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { required, error } = useFormUtils({ errors, schema });

  useEffect(() => {
    resetForm();
  }, [job]);

  const resetForm = () => {
    reset({
      name: job?.name || "",
      photo: job?.photo || null,
      serviceId: job?.service?.id || "",
      clientLogo: job?.clientLogo || null,
      description: job?.description || "",
      challenge: job?.challenge || null,
      solution: job?.solution || null,
      processes: job?.processes || [],
    });
  };

  const submitSaveJob = (formData) => onSubmitSaveJob(formData);

  return (
    <Row>
      <Col span={24}>
        <Title level={3}>Job</Title>
      </Col>
      <Col span={24}>
        <Form onSubmit={handleSubmit(submitSaveJob)}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, name } }) => (
                  <Input
                    label="Nombre"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={error(name)}
                    required={required(name)}
                  />
                )}
              />
            </Col>
            <Col xs={24} sm={12}>
              <Controller
                name="serviceId"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <Select
                    label="Tipo de servicio"
                    value={value}
                    onChange={onChange}
                    error={error(name)}
                    required={required(name)}
                    options={services.map((service) => ({
                      code: service.id,
                      label: capitalize(service.name),
                      value: service.id,
                    }))}
                  />
                )}
              />
            </Col>
            <Col xs={24} sm={24}>
              <Controller
                name="photo"
                control={control}
                defaultValue={null}
                render={({ field: { onChange, value, name } }) => (
                  <Upload
                    label="Foto de trabajo (1480x650)"
                    accept="image/*"
                    name={name}
                    value={value}
                    filePath={`jobs/${job.id}`}
                    buttonText="Subir imagen"
                    error={error(name)}
                    required={required(name)}
                    onChange={(file) => onChange(file)}
                    onUploading={setUploadingImage}
                  />
                )}
              />
            </Col>
          </Row>
          <Card title="Detalle">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={16}>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value, name } }) => (
                    <TextArea
                      label="Descripcion del cliente"
                      rows={8}
                      name={name}
                      value={value}
                      onChange={onChange}
                      error={error(name)}
                      required={required(name)}
                    />
                  )}
                />
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Controller
                  name="clientLogo"
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value, name } }) => (
                    <Upload
                      label="Logo del cliente (250x250)"
                      accept="image/*"
                      name={name}
                      value={value}
                      filePath={`jobs/${job.id}/clientLogo`}
                      buttonText="Subir imagen"
                      error={error(name)}
                      required={required(name)}
                      onChange={(file) => onChange(file)}
                      onUploading={setUploadingImage}
                    />
                  )}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Card title="Reto">
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Controller
                        name="challenge.description"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value, name } }) => (
                          <TextArea
                            label="Descripcion"
                            rows={4}
                            name={name}
                            value={value}
                            onChange={onChange}
                            error={error(name)}
                            required={required(name)}
                          />
                        )}
                      />
                    </Col>
                    <Col span={24}>
                      <Controller
                        name="challenge.photo"
                        control={control}
                        defaultValue={null}
                        render={({ field: { onChange, value, name } }) => (
                          <Upload
                            label="Foto reto (700x700)"
                            accept="image/*"
                            name={name}
                            value={value}
                            filePath={`jobs/${job.id}/challenge`}
                            buttonText="Subir imagen"
                            error={error(name)}
                            required={required(name)}
                            onChange={(file) => onChange(file)}
                            onUploading={setUploadingImage}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} sm={12}>
                <Card title="Solución">
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Controller
                        name="solution.description"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value, name } }) => (
                          <TextArea
                            label="Descripcion"
                            rows={4}
                            name={name}
                            value={value}
                            onChange={onChange}
                            error={error(name)}
                            required={required(name)}
                          />
                        )}
                      />
                    </Col>
                    <Col span={24}>
                      <Controller
                        name="solution.photo"
                        control={control}
                        defaultValue={null}
                        render={({ field: { onChange, value, name } }) => (
                          <Upload
                            label="Foto solución (700x700)"
                            accept="image/*"
                            name={name}
                            value={value}
                            filePath={`jobs/${job.id}/solution`}
                            buttonText="Subir imagen"
                            error={error(name)}
                            required={required(name)}
                            onChange={(file) => onChange(file)}
                            onUploading={setUploadingImage}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Card>

          <Row>
            <Col span={24}>
              <Controller
                name="processes"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <ProcessesList
                    job={job}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required={required(name)}
                    error={error(name)}
                  />
                )}
              />
            </Col>
          </Row>
          <Row justify="end" gutter={[16, 16]}>
            <Col xs={24} sm={6} md={4}>
              <Button
                type="default"
                size="large"
                block
                onClick={() => onGoBack()}
                disabled={uploadingImage | savingJob}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24} sm={6} md={4}>
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                disabled={uploadingImage | savingJob}
                loading={savingJob}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};
