import React, { useEffect, useState } from "react";
import { Modal } from "../Modal";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../Input";
import { Upload } from "../../Upload";
import { Button } from "../Button";
import { Form } from "../Form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormUtils } from "../../../../hooks";
import Title from "antd/lib/typography/Title";
import { TextArea } from "../TextArea";
import { concat, orderBy } from "lodash";
import Card from "antd/lib/card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Image from "antd/lib/image";
import styled from "styled-components";
import List from "antd/lib/list";
import { Skeleton } from "antd";
import { modalConfirm } from "../modalConfirm";
import { notification } from "../notification";
import { InputNumber } from "../InputNumber";

export const Process = ({
  open,
  service,
  onSubmitSaveService,
  onSetModal,
  onSetModalProcess,
}) => {
  const [savingService, setSavingService] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [process, setProcess] = useState(null);
  const [actionType, setActionType] = useState("add");

  const onSetProcess = (process) => setProcess(process);

  const onSetActionType = (actionType) => setActionType(actionType);

  const schema = yup.object({
    number: yup.number().required(),
    name: yup.string().required(),
    description: yup.string().required(),
    photo: yup.mixed().notRequired(),
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { required, error } = useFormUtils({ errors, schema });

  const resetForm = () => {
    reset({
      number: process?.number || null,
      name: process?.name || "",
      description: process?.description || "",
      photo: process?.photo || null,
    });
  };

  useEffect(() => {
    resetForm();
  }, [actionType, process, setProcess]);

  const submitSaveProcesses = async (formData) => {
    try {
      setSavingService(true);

      if (actionType === "add") {
        const existsProcessWithNumber = (service?.processes || []).find(
          (process) => process.number === formData.number
        );

        if (existsProcessWithNumber)
          return notification({
            type: "warning",
            title: "Ya existe un proceso con este numero!",
          });
      }

      const processes = mapProcess(actionType, formData);

      await onSubmitSaveService(processes);

      onSetModal();
    } finally {
      onSetActionType("add");
      onSetProcess(null);
      setSavingService(false);
    }
  };

  const mapProcess = (actionType, formData) => {
    if (actionType === "edit") {
      const newArrayProcesses = service?.processes.filter(
        (process) => process.number !== formData.number
      );

      return {
        processes: concat(newArrayProcesses, {
          number: formData.number,
          name: formData.name.toLowerCase(),
          description: formData.description.toLowerCase(),
          photo: formData?.photo || process?.photo || null,
        }),
      };
    }

    return {
      processes: concat(service?.processes || [], {
        number: formData.number,
        name: formData.name.toLowerCase(),
        description: formData.description.toLowerCase(),
        photo: formData?.photo || null,
      }),
    };
  };

  const processesView = orderBy(service?.processes || [], ["number", "asc"]);

  const onRemoveProcess = async (process) => {
    const processes_ = service.processes.filter(
      (process_) => process_.number !== process.number
    );

    modalConfirm({
      content: "El proceso se eliminará.",
      onOk: async () => {
        await onSubmitSaveService({ processes: processes_ });
        onSetProcess(null);
        onSetActionType("add");
        onSetModal();
      },
    });
  };

  return (
    <>
      <Card
        title="Procesos"
        extra={
          <span className="pointer">
            <Button
              type="primary"
              onClick={() => {
                onSetActionType("add");
                onSetProcess(null);
                return onSetModalProcess();
              }}
            >
              <FontAwesomeIcon icon={faPlus} size="lg" /> &nbsp; Add
            </Button>
          </span>
        }
        bodyStyle={{ display: !service?.processes ? "none" : "inherit" }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <List
              className="demo-loadmore-list"
              itemLayout="horizontal"
              dataSource={processesView}
              renderItem={(process) => (
                <List.Item
                  actions={[
                    <a
                      key="list-loadmore-remove"
                      onClick={() => {
                        return onRemoveProcess(process);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} color="red" size="lg" />
                    </a>,
                    <a
                      key="list-loadmore-edit"
                      onClick={() => {
                        onSetActionType("edit");
                        onSetProcess(process);
                        return onSetModalProcess();
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} color="black" size="lg" />
                    </a>,
                  ]}
                >
                  <Skeleton avatar title={true} loading={!service} active>
                    <List.Item.Meta
                      avatar={
                        process?.photo && (
                          <Image
                            width={100}
                            height={60}
                            src={process.photo.url}
                          />
                        )
                      }
                      title={
                        <h5>
                          {process.number}: {process.name}
                        </h5>
                      }
                      description={process.description}
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Card>
      <Modal
        closable
        open={open}
        onCancel={() => onSetModal()}
        title={<Title level={2}>Procesos</Title>}
      >
        <Form onSubmit={handleSubmit(submitSaveProcesses)}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Controller
                name="number"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, name } }) => (
                  <InputNumber
                    label="Numero"
                    name={name}
                    value={value}
                    disabled={actionType === "edit"}
                    onChange={onChange}
                    error={error(name)}
                    required={required(name)}
                  />
                )}
              />
            </Col>
            <Col span={24}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, name } }) => (
                  <Input
                    label="Nombre"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={error(name)}
                    required={required(name)}
                  />
                )}
              />
            </Col>
            <Col span={24}>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, name } }) => (
                  <TextArea
                    label="Descripción"
                    rows={4}
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={error(name)}
                    required={required(name)}
                  />
                )}
              />
            </Col>
            <Col span={24}>
              <Controller
                name="photo"
                control={control}
                defaultValue={null}
                render={({ field: { onChange, value, name } }) => (
                  <Upload
                    key={process?.photo}
                    label="Foto de proceso (700x700)"
                    accept="image/*"
                    name={name}
                    value={value}
                    filePath={`services/${service.id}/processes`}
                    buttonText="Subir imagen"
                    error={error(name)}
                    required={required(name)}
                    onChange={(file) => onChange(file)}
                    onUploading={setUploadingImage}
                  />
                )}
              />
            </Col>
          </Row>
          <Row justify="end" gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8}>
              <Button
                type="default"
                size="large"
                block
                onClick={() => {
                  onSetActionType("add");
                  onSetProcess(null);
                  return onSetModal();
                }}
                disabled={uploadingImage | savingService}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                disabled={uploadingImage | savingService}
                loading={savingService}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const TitleItem = styled.div`
  display: flex;
  gap: 0.5em;
  font-size: 1em;
  text-transform: capitalize;
`;
