import React from "react";
import styled from "styled-components";
import {
  AvatarAboutUs,
  BannerAboutUsDesk,
  BannerAboutUsMb,
  Logo,
} from "../../images";
import { mediaQuery } from "../../styles";
import { HelmetComponent } from "../../components";
import { useDevice } from "../../hooks";

export const AboutUs = () => {
  const { isMobile } = useDevice();

  return (
    <>
      <HelmetComponent
        config={{
          title: `IUBI - Sobre nosotros`,
          url: "https://iubi.pe/about-us",
          image:
            "https://iubi.pe/static/media/banner-1.38fd62cfff3d2b466fa9.webp",
        }}
      />
      <Container>
        <div className="wrapper-banner">
          <img
            src={isMobile ? BannerAboutUsMb : BannerAboutUsDesk}
            alt="sobre nosotros - iubi"
          />
        </div>
        <div className="wrapper-items">
          <div className="content-left">
            <img src={AvatarAboutUs} alt="Sobre nosotros - iubi" />
          </div>
          <div className="content-right">
            <div className="content-texts">
              <h4>LOS MEJORES PROFESIONALES A TU SERVICIO</h4>
              <p>
                Somos una agencia de marketing innovadora y dinámica, con un
                enfoque en brindar soluciones integrales de marketing a nuestros
                clientes. Nos esforzamos por comprender las necesidades únicas
                de cada uno de nuestros clientes y trabajamos incansablemente
                para ayudarlos a alcanzar sus objetivos de marketing.
              </p>
              <p>
                Contamos con un equipo capacitado y experimentado de
                profesionales de marketing, incluyendo expertos en estrategia,
                publicidad, contenido,diseñadores, programadores y análisis de
                datos. Juntos, combinamos nuestra pasión por el marketing con
                nuestra tecnología avanzada para brindar soluciones
                personalizadas y efectivas a cada uno de nuestros clientes.
              </p>
              <p>
                Creemos en la colaboración y en trabajar de manera transparente
                con nuestros clientes para asegurarnos de que estamos alineados
                con sus objetivos y que estamos avanzando juntos hacia un futuro
                exitoso.
              </p>
              <p>
                Nos apasiona el marketing y nos dedicamos a estar a la
                vanguardia de las tendencias y tecnologías para asegurarnos de
                que nuestros clientes siempre estén un paso adelante de su
                competencia.
              </p>
              <p>
                Si está buscando una agencia de marketing confiable y dedicada,
                ¡no busque más! ¡Estamos aquí para ayudarlo a alcanzar sus metas
                de marketing y a impulsar su negocio al siguiente nivel!
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: auto;
  .wrapper-banner {
    img {
      width: 100%;
      height: auto;
    }
  }
  .wrapper-items {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    ${mediaQuery.minDesktop} {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
    }
    .content-left {
      display: flex;
      align-items: end;
      justify-content: center;
      order: 2;
      ${mediaQuery.minDesktop} {
        justify-content: end;
        order: 0;
      }
      img {
        width: 77%;
        max-width: 37em;
        height: auto;
      }
    }
    .content-right {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5em 0;
      ${mediaQuery.minDesktop} {
        padding: 7em 0 5em 0;
        justify-content: start;
      }
      .content-texts {
        width: 90%;
        text-align: center;
        ${mediaQuery.minDesktop} {
          width: 82%;
        }
        h4 {
          color: ${({ theme }) => theme.colors.primary};
          font-size: ${({ theme }) => theme.font_sizes.xx_large};
          margin-bottom: 1.2em;
        }
        p {
          font-size: ${({ theme }) => theme.font_sizes.medium};
          color: ${({ theme }) => theme.colors.font3};
          font-family: "MontserratLight", serif;
          text-align: justify;
          padding-top: 0.2em;
          ${mediaQuery.minDesktop} {
            padding-top: 0.5em;
          }
        }
      }
    }
  }
`;
