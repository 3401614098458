import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { useNavigate, useParams } from "react-router";
import typography from "antd/lib/typography";
import {
  Benefit,
  Button,
  Information,
  notification,
  Spinner,
} from "../../../../components/admin/ui";
import { useDefaultFirestoreProps } from "../../../../hooks";
import { firestore } from "../../../../firebase";
import { useGlobalData } from "../../../../providers";
import { assign, capitalize } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Process } from "../../../../components/admin/ui/services/Process";
import Space from "antd/lib/space";

const { Title } = typography;

export const ServiceIntegration = () => {
  const navigate = useNavigate();
  const { serviceId } = useParams();
  const { services } = useGlobalData();

  const { assignCreateProps, assignUpdateProps } = useDefaultFirestoreProps();
  const [service, setService] = useState({});
  const [modalInformation, setModalInformation] = useState(false);
  const [modalBenefit, setModalBenefit] = useState(false);
  const [modalProcess, setModalProcess] = useState(false);

  const onSetModalInformation = () => setModalInformation(!modalInformation);
  const onSetModalBenefit = () => setModalBenefit(!modalBenefit);
  const onSetModalProcess = () => setModalProcess(!modalProcess);

  useEffect(() => {
    const _service =
      serviceId === "new"
        ? { id: firestore.collection("services").doc().id }
        : services.find((service) => service.id === serviceId);

    if (!_service) return navigate(-1);

    setService(_service);
  }, [modalInformation, modalBenefit, modalProcess]);

  const onSubmitSaveService = async (formData) => {
    try {
      await firestore
        .collection("services")
        .doc(service.id)
        .set(
          serviceId === "new"
            ? assignCreateProps(mapService(service, formData))
            : assignUpdateProps(mapService(service, formData)),
          { merge: true }
        );

      notification({ type: "success" });
    } catch (e) {
      console.log("ErrorSaveService: ", e);
      notification({ type: "error" });
    }
  };

  const mapService = (service, formData) => {
    return assign(
      {},
      {
        id: service.id,
        name: formData?.name || service.name || "",
        servicePhoto: formData?.servicePhoto || service.servicePhoto || null,
        coverPhoto: formData?.coverPhoto || service.coverPhoto || null,
        benefit: formData?.benefit || service.benefit || null,
        processes: formData?.processes || service.processes || null,
      }
    );
  };

  const onGoBack = () => navigate(-1);

  if (!service) return <Spinner height="70vh" />;

  return (
    <Service
      service={service}
      onGoBack={onGoBack}
      onSubmitSaveService={onSubmitSaveService}
      modalInformation={modalInformation}
      modalBenefit={modalBenefit}
      modalProcess={modalProcess}
      onSetModalInformation={onSetModalInformation}
      onSetModalBenefit={onSetModalBenefit}
      onSetModalProcess={onSetModalProcess}
    />
  );
};

const Service = ({
  service,
  onGoBack,
  onSubmitSaveService,
  modalInformation,
  modalBenefit,
  modalProcess,
  onSetModalInformation,
  onSetModalBenefit,
  onSetModalProcess,
}) => {
  return (
    <Container>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space align="center">
            <Button type="primary" onClick={() => onGoBack()}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Title level={3} style={{ margin: "0" }}>
              {capitalize(service.name)}
            </Title>
          </Space>
        </Col>
        <Col span={24}>
          <Information
            key={service.id}
            service={service}
            open={modalInformation}
            onSubmitSaveService={onSubmitSaveService}
            onSetModal={onSetModalInformation}
            onSetModalInformation={onSetModalInformation}
          />
        </Col>
        <Col span={24}>
          <Benefit
            key={service?.benefit}
            service={service}
            open={modalBenefit}
            onSubmitSaveService={onSubmitSaveService}
            onSetModal={onSetModalBenefit}
            onSetModalBenefit={onSetModalBenefit}
          />
        </Col>
        <Col span={24}>
          <Process
            service={service}
            open={modalProcess}
            onSubmitSaveService={onSubmitSaveService}
            onSetModal={onSetModalProcess}
            onSetModalProcess={onSetModalProcess}
          />
        </Col>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  .ant-image-img {
    object-fit: cover;
  }
`;
